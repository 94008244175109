/* eslint-disable */
import React from 'react';

const dataPrice = {
   data: [
        {
            id: "1",
            price : '45€',
            deposit: 1000
        },
        {
            id: "2",
            price: '90€',
            deposit: 2000
        },
        {
            id: "3",
            price: '135€',
            deposit: 3000
        },
        {
            id: "4",
            price: '180€',
            deposit: 4000
        },
        {
            id: "5",
            price: '225€',
            deposit: 5000
        },
        {
            id: "6",
            price: '270€',
            deposit: 6000
        },
        {
            id: "7",
            price: '315€',
            deposit: 7000
        },
        {
            id: "8",
            price: '360€',
            deposit: 8000
        },
        {
            id: "9",
            price: '405€',
            deposit: 9000
        },
        {
            id: "10",
            price: '450€',
            deposit: 10000
        },
    ]
};
export default dataPrice;