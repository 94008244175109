import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
import ModalSection from "../../components/common/ModalSection";


import { Text } from '../../containers/Language';

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      language: ''
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-half"
          data-image-src="images/bg-home.jpg"
          id="home"
          style={{ backgroundColor: "#fdf8f4" }}
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={8} className="text-black text-center">
                    <h1 style={{ color: '#EB502E'}} className="home-title">
                      <Text tid="section.title" />
                    </h1>
                    <p className="pt-3 home-desc" style={{ color: "#000000"}}>
                      <Text tid="section.subtitle" />
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="bg-pattern-effect">
            <img src="assets/images/bg-pattern-no-light.png" alt="ipocamp" />
          </div>
          {/* Render ModalSection Component for Modal */}
          <ModalSection ref="child" channel="vimeo" videoId="99025203" />
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
