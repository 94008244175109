import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import { Form, Field, Formik } from 'formik';

import axios from 'axios';

import { useHistory } from 'react-router-dom';

// import { AvForm, AvField } from "availity-reactstrap-validation";

import { Text } from '../../containers/Language';

import { backLink } from '../../constants/defaultValues';

import LanguageSelector from '../../components/LanguageSelector';

const VerificationCode = () => {
  const [codeVerif, setcodeVerif] = useState('');
  const [error, seterror] = useState(false);
  const [language, setlanguage] = useState("");
  const [confirmToken, setconfirmToken] = useState();

  let history = useHistory();
  
  useEffect(() => {
    setconfirmToken(localStorage.getItem('confirmToken'));
    // console.log('confirmToken', confirmToken);
    // console.log('codeVerif', codeVerif);
  }, [confirmToken, codeVerif])

  const confirmCode = () => {
    axios({
      method: 'post',
      url: `${backLink}/auth/confirm?token=${codeVerif}`,
      headers: {
        "Authorization": `Bearer ${confirmToken}`,
        'Content-Type': 'application/json'
      },
      // data : {
      //   "codeverif": codeVerif,
      // }
    })
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      history.push('/login');
    })
    .catch(function (error) {
      console.log(error);
      seterror(true);
    });
  }
const handleSubmit = () => {
  if(codeVerif !== '') {
    seterror(false);
    confirmCode();
  } else {
    seterror(true);
  }
}
  const handlecodeVerif = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
      setcodeVerif(e.target.value);
    }
  }
  const errorNotification = () => {
    let locallang = localStorage.getItem('rcml-lang');
    if(language) {
      if(language === "en") {
        return(
            "Enter the verification code"
        )
      } else {
        return (
          "Entrez le code de vérification"
        )
      }
    } else {
      if(locallang === "en") {
        return(
          "Enter the verification code"
        )
        } else {
          return (
            "Entrez le code de vérification"
          )
        }
    }
  }
  const placeholdercodeVerif = () => {
    if(language === 'en') {
      return "000000";
    } else {
      return "000000";
    }
  }
    return (
      <React.Fragment>
        <div className="account-home-btn d-none d-sm-block">
          <Link to="/" className="text-white">
            <i className="mdi mdi-home h1"></i>
          </Link>
        </div>

        <section className="bg-account-pages vh-100">
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={5}>
                    <Card className="account-card">
                      <CardBody>
                      <LanguageSelector setlanguage={setlanguage} />
                        <div className="text-center mt-3">
                          <h3 className="font-weight-bold">
                            <Link
                              to="/"
                              style={{ color: "#eb502e"}}
                              className="text-uppercase account-pages-logo"
                            > 
                              <img
                                src="../assets/images/logo/ipocamp.png"
                                style={{          
                                  height: 35,
                                  width: "110.63px",
                                  margin: 0,
                                }}
                                alt="logo"
                              />
                            </ Link>
                          </h3>
                          <p className="text-muted"><Text tid="code.subtitle" /></p>
                        </div>
                        <div className="p-3">
                          <div
                            className="alert alert-warning  text-center"
                            role="alert"
                          >
                            <Text tid="code.warning" />
                          </div>
                          <Formik 
                            style={{ backgroundColor: "#FFFFFF"}}
                            onSubmit={handleSubmit}
                          >
                          <Form>
                            <FormGroup>
                            <Label 
                                htmlFor="firstname"
                                style={{
                                  fontSize: "10px",
                                  marginTop: "10px",
                                  marginLeft: '10px',
                                  marginBottom: "0px",
                                  height: '15px',
                                  color: "#787878",
                                  backgrounColor: '#FFFFFF',
                                  display: 'flex',
                                  position: 'relative',
                                  zIndex:10,
                                  width: '150px'
                                }}
                              ><Text tid="code.code" /></Label>
                              <Field
                                onChange={handlecodeVerif}
                                name="newPassword"
                                // required
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder={placeholdercodeVerif()}
                              />
                            </FormGroup>
                            <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-10px", color:"red"}}>
                            { error === true ? (errorNotification()) : null}
                            </div>
                            <div className="d-grid mt-3">
                              <Button
                                onClick={handleSubmit}
                                color="none"
                                className="bg-color-chart text-chart"
                              >
                                <Text tid="code.validationbutton" />
                              </Button>
                            </div>
                          </Form>
                        </Formik>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
}

export default VerificationCode;
