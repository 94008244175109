import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import { Text } from '../../containers/Language';

import LanguageSelector from '../../components/LanguageSelector';
import { bake_cookie } from 'sfcookies';
import axios from 'axios';

import { backLink } from '../../constants/defaultValues';

import { useHistory } from 'react-router-dom';
import { Form, Field, Formik } from 'formik';
import jwt_decode from 'jwt-decode';

const Login = () => {

  // eslint-disable-next-line no-unused-vars

  let history = useHistory();

  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [error, seterror] = useState(false);
  const [language, setlanguage] = useState("");
  const [accessToken, setaccessToken] = useState("");
  const [errorEmptyEmail, seterrorEmptyEmail] = useState(false);
  const [errorEmptyPassword, seterrorEmptyPassword] = useState(false);

  
const handlejwtdecode = (jwt) => {
  // console.log("jwtdecode", jwt);
  const decode = jwt_decode(jwt);
  // console.log("decode", decode);
  localStorage.setItem('client_id', decode.client_id);
  localStorage.setItem('permission_level', decode.permission_level);
  localStorage.setItem('login', true);
}
const handleSubmit = () => {
  if( email === '') {
    seterrorEmptyEmail(true);
  } else {
    seterrorEmptyEmail(false);
  }
  if( password === '') {
    seterrorEmptyPassword(true);
  } else {
    seterrorEmptyPassword(false);
  }
  if(email !== '' && password !== '') {
    userLogin();
  }
}
  const userLogin = () => {
    var config = {
      method: 'post',
      url: `${backLink}/auth`,
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      data : {
        "email": email,
        "password": password
      }
    };
    
    axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // console.log(response.data);
      // console.log("token", response.data.token);
      // console.log(response.data.accessToken);
      // console.log('refreshToken', response.data.refreshToken);
      window.localStorage.setItem('accessToken', response.data.accessToken);
      bake_cookie('refreshToken', response.data.refreshToken);
      // setjwtdecode(jwt_decode(response.data.token, { header: true}));

      if(JSON.stringify(response.data.refreshToken) !== "") {
          handlejwtdecode(response.data.accessToken);
          seterror(false);
          history.push('/dashboard');
      }
    })
    .catch(function (error) {
      seterror(true);
      console.log(error);

    });
  }
  useEffect(() => {
    setaccessToken(window.localStorage.getItem('accessToken'));
    // console.log('accessToken', accessToken);
  }, [accessToken])
const handleEmail = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setemail(e.target.value);
  }
}
const handlePassword = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setpassword(e.target.value);
  }
}
const errorNotificationAllFieldsEmpty = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "This field is empty"
    )
    } else {
      return (
          "Ce champ est vide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
          "This field is empty"
      )
      } else {
        return (
            "Ce champ est vide"
        )
      }
  }
}
const errorNotification = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
      <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
        Your email address or your password is invalid
      </div>
    )
    } else {
      return (
        <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
          Votre adresse email ou votre mot de passe est incorrect
        </div>
      )
    }
  } else {
    if(locallang === "en") {
      return(
        <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
          Your email address or your password is invalid
        </div>
      )
      } else {
        return (
          
          <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
          Votre adresse email ou votre mot de passe est incorrect
          </div>
        )
      }
  }
}
 const placeholderEmail = () => {
   if(language === 'en') {
     return "";
   }
    else {
     return "";
   }
 }
 const placeholderPassword = () => {
   if(language === 'en') {
     return "";
   } else {
     return "";
   }
 }
  return (
    <React.Fragment>
      <div className="account-home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="mdi mdi-home h1"></i>
        </Link>
      </div>

      <section className="bg-account-pages vh-100">
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5}>
                  <Card className="account-card">
                    <CardBody>
                    <LanguageSelector setlanguage={setlanguage} />
                      <div className="text-center mt-3">
                        <h3 className="font-weight-bold">
                          <Link
                            to="/"
                            style={{ color: "#eb502e"}}
                            className="text-uppercase account-pages-logo"
                          >
                            <img
                              src="../assets/images/logo/ipocamp.png"
                              style={{          
                                height: 35,
                                width: "110.63px",
                                margin: 0,
                              }}
                              alt="logo"
                            />
                          </ Link>
                        </h3>
                        <p className="text-muted">
                          <Text tid="login.subtitle" />
                        </p>
                      </div>
                      <div className="p-3">
                      <Formik 
                        style={{ backgroundColor: "#FFFFFF"}}
                        onSubmit={handleSubmit}
                        // onSubmit={consoleLog}
                      >
                        <Form>
                          <FormGroup>
                            <Label 
                              htmlFor="email"
                              style={{
                                fontSize: "10px",
                                marginLeft: '10px',
                                marginBottom: "0px",
                                height: '15px',
                                color: "#787878",
                                backgrounColor: '#FFFFFF',
                                display: 'flex',
                                position: 'relative',
                                zIndex:10,
                                width: '90px'
                              }}
                          >
                              <Text tid="login.email" />
                            </Label>
                            <Field
                              onChange={handleEmail}
                              type="text"
                              name="email"
                              className="form-control"
                              id="email"
                              placeholder={placeholderEmail()}
                            />
                            <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-10px", color:"red"}}>
                              {errorEmptyEmail === true ? (errorNotificationAllFieldsEmpty()) : null}
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              className="mt-4"
                              htmlFor="userpassword"
                              style={{
                                fontSize: "10px",
                                marginLeft: '10px',
                                marginBottom: "0px",
                                height: '15px',
                                color: "#787878",
                                backgrounColor: '#FFFFFF',
                                display: 'flex',
                                position: 'relative',
                                zIndex:10,
                                width: '90px'
                              }}><Text tid="login.password" /></Label>
                            <Field
                              onChange={handlePassword}
                              type="password"
                              name="password"
                              className="form-control mb-4"
                              id="userpassword"
                              placeholder={placeholderPassword()}
                            />
                            <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
                              {errorEmptyPassword === true ? (errorNotificationAllFieldsEmpty()) : null}
                            </div>
                          </FormGroup>
                          { error === true ? (errorNotification()) : null}
                          {/* <div className="custom-control custom-checkbox">
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="customControlInline"
                            />{" "}
                            <Label
                              // onClick={handlejwtdecode}
                              className="custom-control-label"
                              htmlFor="customControlInline"
                            >{" "}
                              Remember me
                            </Label>
                          </div> */}

                          <div className="d-grid mt-3">
                            <Button
                              onClick={handleSubmit}
                              // onKeyUp={handleSubmit}
                              color="none"
                              className="mt-3 btn bg-color-chart text-chart"
                            >
                              <Text tid="login.validationbutton" />
                            </Button>
                          </div>
                          <div className="mt-3 mb-0 text-center">
                            <Link to="/password-forget" className="text-dark">
                              <i className="mdi mdi-lock"></i>
                              <Text tid="login.forget" />
                            </Link>
                          </div>
                          <div className="mt-2 mb-0 text-center">
                              <p><Text tid="login.register" /> <Link to="/sign-up" style={{color: '#EB502E'}}><Text tid="login.register-redirect" /></Link></p>
                          </div>
                        </Form>
                      </Formik>  
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Login;
