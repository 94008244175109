import React, { Component } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardText
  } from "reactstrap";
import { Link } from "react-router-dom";
import { Colxx } from "../../components/common/CustomBootstrap";
import { Text } from '../../containers/Language';

class Process extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    console.log('propsdetails', this.props.box);
  }
  render() {
    return (
      <React.Fragment>
        <section className="mt-4" id="process" style={{ backgroundColor: "#fdf8f4" }}>
                <Row
                    md="1"
                    style={{
                        width: '100%',
                        display:'flex',
                        justifyContent:'center',
                        marginBottom: "-20px"
                    }}
                >
                    <Colxx
                        sm="4"
                        className=""
                        style={{
                          width: '638px',
                          height: '358px'
                        }}
                        md="4"
                    >     
                        <Card
                            style={{
                                backgroundColor: "#fdf8f4",
                                border: 'none'
                            }}
                        >
                            <CardImg
                                alt="Card image cap"
                                src={`assets/images/details/details_picture${this.props.box}.png`}
                                top
                                style={{
                                    width: "638px",
                                    height: "358px",
                                    borderRadius: "5%"
                                }}
                            />
                        </Card>
                    </Colxx>
                    <Colxx
                        sm="4"
                        className=""
                        style={{
                          width: '638px',
                          height: '358px'
                        }}
                        md="4"
                    >
                      <Card className="mt-5" style={{ backgroundColor: "#fdf8f4", border: "none"}}>
                        <CardBody>
                            <CardTitle tag="h5">
                              <Text tid={`details.title${this.props.box}`} />
                            </CardTitle>
                            <CardSubtitle
                                className="mb-2 text-muted"
                                tag="h6"
                            >
                              <Text tid={`details.subtitle${this.props.box}`} />
                            </CardSubtitle>
                            <CardText>
                              <Text tid={`details.text${this.props.box}`} />
                            </CardText>
                            <div>
                                <Link
                                to="/documentation"
                                className="btn bg-color-chart text-chart  waves-light waves-effect"
                                style={{ backgroundColor: '#eb502e'}}
                                >
                                  <Text tid={`details.button${this.props.box}`} /> <i className="mdi mdi-arrow-right"></i>
                                </Link>
                            </div>
                        </CardBody>
                        </Card>
                    </Colxx>
                </Row>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
