/* eslint-disable camelcase */
import React from 'react';
import jwt_decode from 'jwt-decode';
import { Route } from 'react-router-dom';
import { bake_cookie, read_cookie } from 'sfcookies'
// import { setCurrentUser } from './Utils';
import { backLink, frontLink } from '../constants/defaultValues';

async function refreshToken() {
  // console.log("refreshtoken style");
  await fetch(`${backLink}/auth/refresh-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      refreshToken: read_cookie('refreshToken'),
    }),
  }).then(async (res) => {
    const tmp = await res.json();
    // console.log(tmp);
    if (res.status === 200) {
      bake_cookie('refreshToken', tmp.refreshToken);
      localStorage.setItem('accessToken', tmp.accessToken);
      const decode = jwt_decode(tmp.accessToken);
      localStorage.setItem('tokenExp', decode.exp);
      // console.log('tokenExp', jwt_decode(tmp.accessToken));
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('client_id');
        localStorage.removeItem('permission_level');
        localStorage.removeItem('current_user');
        localStorage.removeItem('confirmToken');
        localStorage.removeItem('tokenExp');
        localStorage.setItem('login', false);
        window.location.href = `${frontLink}/login`;
      };
  })
  .catch(() => {
    localStorage.setItem('login', "false");
    window.location.href = `${frontLink}/login`;
  });
}
// async function updateUser(id, accessToken) {
//   await await fetch(
//     `${backLink}/clients/${id}`,
//     {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         // prettier-ignore
//         'Authorization': `Bearer ${accessToken}`,
//       },
//     }
//   ).then(async (res) =>{
//     const tmp = await res.json();
//     // console.log(tmp)
//     if (res.status === 200) {
//       const item = {
//         id: tmp._id,
//         api_key: tmp.api_key,
//         email : tmp.email,
//         first_name: tmp.first_name,
//         last_deposit: tmp.last_deposit,
//         current_month_deposits: tmp.current_month_deposits,
//         last_name: tmp.last_name,
//         permission_level: tmp.permission_level,
//         total_deposits: tmp.total_deposits,
//         company: tmp.company.name,
//         siret: tmp.company.siret,
//         allowed_monthly_deposits: tmp.allowed_monthly_deposits
//       };
//       setCurrentUser(item);
//   }
//   });
// }
const ProtectedRoute = ({ component: Component, navItems, ...rest }) => {
  return (
    <Route {...rest} render={
      props => {
      const current = Date.now();
      const expire = localStorage.getItem('tokenExp');
      // const log = localStorage.getItem('login');
      // console.log("current", current);
      // console.log("expire", expire);
        if (current > expire) {
          // console.log("current > expire");
          if(read_cookie('refreshToken')) {
            // console.log("read_cookie('refreshToken')");
            refreshToken();
            return <Component {...rest} navItems={navItems} {...props} />
          } else {
            // console.log("else de read_cookie('refreshToken')");
            window.location.href = `${frontLink}/login`;
          }
        } else {
          refreshToken();
        }
        return <Component {...rest} {...props} />
      }
    } />
  )
}
// const ProtectedRoute = ({ component: Component, navItems, ...rest }) => {
//   return (
//     <Route {...rest} render={
//       props => {
//       const current = Date.now();
//       const expire = localStorage.getItem('tokenExp');
//       const log = localStorage.getItem('login');
//       console.log("current", current);
//       console.log("expire", expire);
//         if (current > expire) {
//           console.log("current > expire");
//           if(read_cookie('refreshToken')) {
//             console.log("read_cookie('refreshToken')");
//             refreshToken();
//             return <Component {...rest} navItems={navItems} {...props} />
//           } else {
//             console.log("else de read_cookie('refreshToken')");
//             window.location.href = `${frontLink}/login`;
//           }
//         } else if (getCurrentUser() === null) {
//           console.log("else if getCurrentUser() === null");
//          return (
//          <Redirect to={
//             {
//               pathname: '/login',
//               state: {
//                 from: props.location
//               }
//             }
//           } />
//          )
//         } else {
//           console.log("else de : else if getCurrentUser() === nulln");
//             updateUser();
//         }
//         return <Component {...rest} {...props} />
//       }
//     } />
//   )
// }
// eslint-disable-next-line import/prefer-default-export
export { ProtectedRoute };

