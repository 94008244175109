import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Row,
  Col,
  Badge,
  ListGroupItem,
  FormGroup,
  Label,
  Button
} from 'reactstrap';
import { Form, Field, Formik } from 'formik';
import Navbar from '../../components/Navbar/Navbar';
import Footer from "../../components/Footer/footer";
import axios from 'axios';
import { Text } from '../../containers/Language';
import MediaQuery from 'react-responsive';
import { backLink } from '../../constants/defaultValues';
import { getCurrentUser, setCurrentUser } from '../../helpers/Utils';
import { 
  successNotificationSiret,
  errorNotificationNoVerifSiret,
  errorNotificationSiret,
  errorNotificationNotSame,
  successNotificationPassword,
  errorNotificationName,
  // errorNotificationEntrepriseEmpty,
  errorNotificationSiretEmpty,
  successNotificationFirstName,
  successNotificationLastName,
  errorNotificationSiretString,
  errorNotificationAllFieldsEmpty,
  errorNotificationCurrentPassword,
  errorNotificationPasswordType,
  
} from '../../helpers/errorNotification';

import Modal from "../../components/containers/ui/Modal";

const useStyles = makeStyles((theme) => ({
  table: {
    height: "260px",
    background: "white",
    maxWidth: "600px",
    margin: "auto",
    color: "black",
    borderRadius: "20px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
  },
  tableResponsive: {
    height: "330px",
    background: "white",
    width: "300px",
    margin: "auto",
    color: "black",
    borderRadius: "20px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    border: "none"
  },
  fields: {
    verticalAlign: "top",
    marginTop: "-10px"
  },
  title: {
    color: "#FFFFFF",
    marginBottom: '10px',
    border: "none",
    backgroundColor: "#eb502e"
  },
  subtitle: {
    fontSize: "13px",
    color: "#787878",
    border: "none",
    marginLeft: '30px',
    marginBottom: "0px",
    marginTop: "10px",
    height: "23px",
  },
  data: {
    color: "#000000",
    border: "none",
    marginTop: "-5px",
    marginLeft: '15px',
    height: "30px",
    fontFamily: 'sans-serif',
    fontSize: "14?4px",
    fontWeight: "bold",
    // position: "relative",
    // top: "-10px"
  },
  // footer: {
  //   position: "static",
  //   left: 0,
  //   bottom: 0,
  //   width: "100%",
  //   marginTop: "auto",
  //   backgroundColor: "#fdf8f4"
  // },
  footer: {
    bottom: '0',
    display: "table",
    width: "100%",
    position: 'static'
  },
  footerResponsive: {
    bottom: '0',
    marginTop: '50px',
    display: "table",
    width: "100%",
    position: 'static'
  },
  footerTab: {
    bottom: '0',
    marginTop: '30px',
    display: "table",
    width: "100%",
    position: 'static'
  },
  input: {
    margin: 'auto',
    width: "350px",
  },
  inputResponsive: {
    margin: 'auto',
    width: "350px",
  },
  textfamily: {
    fontFamily: 'sans-serif',
    fontSize: "1em"
  }
}));

const Example = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [language, setlanguage] = useState('');
  const [accessToken, setaccessToken] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [clientId, setclientId] = useState('');
  // const [apiKey, setapiKey] = useState('');

  const [company, setcompany] = useState('');
  const [siret, setsiret] = useState('');

  const [currentPassword, setcurrentPassword] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');

  const [entreprise, setentreprise] = useState('')

  const [successSiret, setsuccessSiret] = useState(false);
  const [errorSiret, seterrorSiret] = useState(false);

  const [resetsiret, setresetsiret] = useState('');
  const [resetentreprise, setresetentreprise] = useState('');
  const [errorConfirm, seterrorConfirm] = useState(false);
  const [errorNew, seterrorNew] = useState(false);
  const [errorCurrent, seterrorCurrent] = useState(false);
  const [errorNotSame, seterrorNotSame] = useState(false);
  const [errorCurrentPassword, seterrorCurrentPassword] = useState(false);
  const [badPasswordType, setbadPasswordType] = useState(false);

  const [successPassword, setsuccessPassword] = useState(false);

  const [resetLastName, setresetLastName] = useState('');
  const [resetFirstName, setresetFirstName] = useState('');

  const [errorEntrepriseEmpty, seterrorEntrepriseEmpty] = useState(false);
  // const [errorSiretString, seterrorSiretString] = useState(false);

  // const [resetSiret, setresetSiret] = useState();
  const [errorSiretEmpty, seterrorSiretEmpty] = useState(false);
  const [errorSiretString, seterrorSiretString] = useState(false);

  const [noVerifSiret, setnoVerifSiret] = useState(false);

  const [successFirstName, setsuccessFirstName] = useState(false);
  const [successLastName, setsuccessLastName] = useState(false);
  // const [successName, setsuccessName] = useState(false);
  const [errorName, seterrorName] = useState(false);
  
  const [errorAllEmpty, seterrorAllEmpty] = useState(false);

  const [passwordHideOne, setpasswordHideOne] = useState(true);
  const [passwordHideTwo, setpasswordHideTwo] = useState(true);
  const [passwordHideThree, setpasswordHideThree] = useState(true);
  const [modalBasic, setmodalBasic] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    // console.log("language", language);
    setaccessToken(window.localStorage.getItem('accessToken'));
    setclientId(window.localStorage.getItem('client_id'))
    if(accessToken === "" || accessToken === null) {
      // console.log('accessToken', accessToken);
      // console.log('clientId', clientId);
      // console.log('permissionLevel', permissionLevel);
      console.log('error')
    } else {
      // console.log("hello it's me");
      userProfile(clientId, accessToken);
    }
  }, [accessToken, clientId, language])

  const userProfile = (client, token) => {
    // console.log('client', client);
    // console.log('token', token);
    // console.log("hello");
    // console.log('propsey', props);
    axios({
      method: 'get',
      url: `${backLink}/clients/${client}`,
      headers: {
        'Authorization': `Bearer ${token}`
      },})
      .then(function (response) {
        // console.log('data', response.data)
        // console.log('getCurrentUser', getCurrentUser());
        props.setfirst_name(response.data.first_name);
        props.setlast_name(response.data.last_name);
        setcompany(response.data.company.name);
        setsiret(response.data.company.siret);
        const item = {
          id: response.data._id,
          api_key: response.data.api_key,
          email : response.data.email,
          first_name: response.data.first_name,
          last_deposit: response.data.deposits.last_deposit,
          current_month_deposits: response.data.deposits.current_month_deposits,
          last_name: response.data.last_name,
          permission_level: response.data.permission_level,
          total_deposits: response.data.deposits.total_deposits,
          company: response.data.company.name,
          siret: response.data.company.siret,
          allowed_monthly_deposits: response.data.deposits.allowed_monthly_deposits,
          subscription_created_at : response.data.stripe.subscription_created_at
        };
        setCurrentUser(item);
        // setfirstname(response.data.first_name);
        // setlastname(response.data.last_name);
        // setemail(response.data.email);
        // // setapiKey(response.data.api_key);
        // setlevel(response.data.permission_level);
        // setcompany(response.data.company.name);
        // setsiret(response.data.company.siret);
      })
      .catch(function (error) {
        console.log(error);
    });
}
  const handleCurrentPassword = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
    setcurrentPassword(e.target.value);
    }
  }
  const handleNewPassword = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
    setnewPassword(e.target.value);
    }
  }
  const handlePasswordConfirm = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
    setconfirmPassword(e.target.value);
    }
  }
  const checkData = () => {
    const strongRegex = new RegExp("^(?=.*[A-Z])(?=.{7,})")
    // console.log('id', userId);
    // console.log('current', currentPassword);
    // console.log('new', newPassword);
    // console.log('confirmPassword', currentPassword);
    if(confirmPassword === '' && newPassword === '' && currentPassword === '') {
      seterrorAllEmpty(true);
      seterrorConfirm(false);
      seterrorNew(false);
      seterrorCurrent(false);
      setbadPasswordType(false);
    }
    if(currentPassword === '') {
      seterrorAllEmpty(false);
      // seterrorConfirm(false);
      // seterrorNew(false);
      seterrorCurrent(true);
      setbadPasswordType(false);
    } else {
      seterrorCurrent(false);
    }
    if(newPassword === '') {
      seterrorAllEmpty(false);
      setbadPasswordType(false);
      // seterrorConfirm(false);
      seterrorNew(true);
      // seterrorCurrent(false);
    } else {
      seterrorNew(false);
    }
    if(confirmPassword === '') {
      seterrorAllEmpty(false);
      seterrorConfirm(true);
      setbadPasswordType(false);
      // seterrorNew(false);
      // seterrorCurrent(false);
    } else {
      seterrorConfirm(false);
    }
    if(newPassword !== '' && confirmPassword !== '' && currentPassword !== '') {
      if(newPassword !== confirmPassword) {
        seterrorAllEmpty(false);
        seterrorNotSame(true);
        setbadPasswordType(false);
        seterrorCurrentPassword(false);
        setsuccessPassword(false);
      } else {
        seterrorNotSame(false);
      if (strongRegex.test(confirmPassword)){
        resetPassword();
        setbadPasswordType(false);
      } else {
        setbadPasswordType(true);
        setsuccessPassword(false);
      }
      }
    }
  }
  // const updatePassword = () => {
  // }
  const resetPassword = () => {
      axios({
        method: 'patch',
        url: `${backLink}/clients/password`,
        headers: { 
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          
          password: currentPassword,
          new_password: newPassword
        }),
      })
      .then(function (response) {
        setsuccessPassword(true);
        seterrorCurrentPassword(false);
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        seterrorCurrentPassword(true);
        setsuccessPassword(false);
        console.log("error", error);
      });
  }
  const handleFirstName = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
      setresetFirstName(e.target.value);
    }
  }
  const handleLastName = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
      setresetLastName(e.target.value);
      // console.log(e.target.value);
    }
  }
  const resetUsername = () => {
    var data;
    if(resetFirstName !== '' && resetLastName === '') {
      data = JSON.stringify({"first_name": resetFirstName});
    }
    if(resetFirstName === '' && resetLastName !== '') {
      data = JSON.stringify({"last_name": resetLastName});
    }
    if(resetFirstName !== '' && resetLastName !== '') {
      data = JSON.stringify({"first_name": resetFirstName, "last_name": resetLastName});
    }
    if(resetFirstName === '' && resetLastName === '') {
      seterrorName(true);
      return null;
    }
    axios({
      method: 'patch',
      url: `${backLink}/clients/profile`,
      headers: { 
        "Authorization": `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      data : data
    })
    .then(function (response) {
      // console.log('profil', response);
      userProfile(response.data._id, localStorage.getItem('accessToken'));
      if(resetFirstName !== '' && resetLastName === '') {
        seterrorName(false);
        setsuccessFirstName(true);
      }
      if(resetFirstName === '' && resetLastName !== '') {
        seterrorName(false);
        setsuccessLastName(true);
      }
      if(resetFirstName !== '' && resetLastName !== '') {
        seterrorName(false);
      }
    })
    .then(function (response) {
      window.location.reload();
      // console.log(JSON.stringify(response.data.patched));
    })
    .catch(function (error) {
      console.log("error", error);
    });
}
const resetCompany = () => {
  var data;
  if(resetsiret !== '') {
  let stringSiret = resetsiret.toString();
    data = JSON.stringify({"siret": stringSiret, "company": entreprise});
    // console.log('data', data)
    // console.log('resetsiret', resetsiret)
  }
  if(resetentreprise !== '') {
    data = JSON.stringify({"siret": ' ', "company": resetentreprise});
  }
  axios({
    method: 'patch',
    url: `${backLink}/clients/profile`,
    headers: { 
      "Authorization": `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    data : data
  })
  .then(function (response) {
    // console.log('profil', response);
    userProfile(response.data._id, localStorage.getItem('accessToken'));
    // console.log(JSON.stringify(response.data.patched));
  })
  .then(function(response) {
    window.location.reload();
  })
  .catch(function (error) {
    console.log("error", error);
  });
}
  const handleEntreprise = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
      setresetentreprise(e.target.value);
      seterrorEntrepriseEmpty(false);
    }
  }
  const handleSiret = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
      seterrorSiretEmpty(false);
      seterrorSiretString(false);
      setresetsiret(e.target.value.replace(/\s/g, ''));
    } else {
      seterrorSiretEmpty(false);
      seterrorSiretString(true);
    }
  }
  const checkResetSiret = () => {
    // console.log('siret', resetSiret);
    if( resetsiret === '' && resetentreprise === '') {
      seterrorSiretEmpty(true);
    } else {
        if(resetsiret.length === 14) {
          if(successSiret) {
            setnoVerifSiret(false);
            seterrorSiretString(false);
            seterrorSiretEmpty(false);
            seterrorSiret(false);
            resetCompany();
          } else {
            setnoVerifSiret(true);
            seterrorSiretString(false);
            seterrorSiretEmpty(false);;
            seterrorSiret(false);
            seterrorSiret(false);
         }
        } else {
          setnoVerifSiret(true);
          seterrorSiretString(false);
          seterrorSiretEmpty(false);;
          seterrorSiret(false);
          seterrorSiret(false);
        }
        if(resetentreprise !== '') {
          setnoVerifSiret(false);
          seterrorSiretString(false);
          seterrorSiretEmpty(false);;
          seterrorSiret(false);
          seterrorSiret(false);
          resetCompany();
        }
      }
    if( resetentreprise === '' && resetsiret === '') {
      seterrorEntrepriseEmpty(true);
      seterrorSiretEmpty(true);
    }
    if (resetsiret !== '' && resetentreprise !== '') {
      // resetCompany();
      console.log("error")
    }
  }
  const checkSiret = () => {
    if(resetsiret === '' && entreprise === '') {
      seterrorSiretEmpty(true);
      setnoVerifSiret(false);
      seterrorSiretString(false);
      seterrorSiret(false);
    } else {
      if(resetsiret.length === 14) {
        var config = {
          method: 'get',
          url: `https://entreprise.data.gouv.fr/api/sirene/v1/siret/${resetsiret}`,
        };
        
        axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          // console.log(response.data.etablissement.nom_raison_sociale);
          if(response.data.etablissement.nom_raison_sociale !== undefined) {
            seterrorSiret(false);
            setsuccessSiret(true);
            seterrorSiretString(false);
            setnoVerifSiret(false);
            setentreprise(response.data.etablissement.nom_raison_sociale);
          }
        })
        .catch(function (error) {
          seterrorSiret(true);
          seterrorSiretString(false);
          setsuccessSiret(false);
          setnoVerifSiret(false);
          console.log(error);
        });   
      } else {
        seterrorSiretEmpty(false);
        setnoVerifSiret(false);
        setsuccessSiret(false);
        seterrorSiretString(true);
        seterrorSiret(false);
      }
    }
  }
  const getByPlaceholderTextCurrent = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === 'fr') {
        return ""
      } else {
        return ""
      }
    } else {
      if(locallang === "en") {
        return(
          ""
        )
        } else {
          return (
            ""
          )
        }
    }
  }
  const getByPlaceholderTextNew = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === 'fr') {
        return ""
      } else {
        return ""
      }
    } else {
    if(locallang === "en") {
      return(
        ""
      )
      } else {
        return (
          ""
        )
      }
  }
  }
  const getByPlaceholderTextConfirm = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === 'fr') {
        return ""
      } else {
        return ""
      }
    } else {
    if(locallang === "en") {
      return(
        ""
      )
      } else {
        return (
          ""
        )
      }
  }
  }
  return (
    <React.Fragment>
    <section className="bg-account-pages vh-100">
         <Navbar language={language} setlanguage={setlanguage} first_name={props.first_name} last_name={ props.last_name } />
      <div style={{ backgroundColor: "#fdf8f4" }}>
      <div className="display-table" style={{ width: "100%", height: "auto", backgroundColor: "#fdf8f4"}}>
        {modalBasic === true ? (
          <div style={{ background: "#fdf8f4"}}>
            <Modal setmodalBasic={setmodalBasic} modalBasic={modalBasic}  unsubscribeRequest={props.unsubscribeRequest} />
          </div>
          ) : null
        }
        <div className="" style={{ marginTop: "130px", backgroundColor: "#fdf8f4" }}>
            <MediaQuery minWidth={780}>
             <Formik 
               style={{ backgroundColor: "#fdf8f4" }}
               onSubmit={checkData}
             >
              <Form
                style={{
                  maxWidth: "500px",
                  minHeight: "930px",
                  height: 'auto',
                  maxHeight: '2000px',
                  marginBottom: '9%',
                  color: "black",
                  background: "#FFFFFF"
                }}
                className={`av-tooltip tooltip-label-bottom ${classes.table}`}
              >
                <ListGroupItem className={classes.title}><Text tid="social.title" /></ListGroupItem>

                <div style={{ margin: 'auto', textAlign: 'center', lineHeight: '2.6', fontSize: '1rem', fontWeight: '500' }}>
                  <span>{`${props.first_name} ${props.last_name}` }</span><br />
                  <span>{getCurrentUser().email}</span><br />
                  {
                  getCurrentUser() !==  null ? (getCurrentUser().permission_level === 1 ? (
                    <Badge
                    style={{ border:'1px solid red', color: 'red', fontWeight: '500', borderRadius: '20px'}}
                      color="#000000"
                      href="#"
                    >
                      PREMIUM
                  </Badge>
                  ) : (
                    <Badge
                      style={{ border:'1px solid red', color: 'red', fontWeight: '500', borderRadius: '20px'}}
                      color="#000000"
                      href="#"
                    >
                      MEMBRE
                  </Badge>
                  )) : null
                }
                <br />
                {
                  getCurrentUser() !== null ? (getCurrentUser().permission_level === 1 ? (
                    <Button
                      onClick={() => setmodalBasic(true)}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#eb502e",
                        border: "none"
                      }}
                    >
                      <Text tid="social.unsubscribe" />
                    </ Button>
                  ) : null) : null
                }
                </div>
                <div>
                    <span style={{ marginLeft: '55px', fontWeight: '100' }}><Text tid="social.title-Name" /></span>
                    <div
                      className=""
                      style={{
                        borderTop: "1px solid #000000",
                        marginLeft: '65px',
                        width: "400px", 
                        margin: "auto",
                        backgroundColor: "#FFFFFF"
                      }}
                    />
                </div>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFF', position: 'relative', zIndex:10, width: '50px', marginTop: '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          marginTop: "10px",
                          marginLeft: '7px',
                          height: '15px',
                          color: "#787878",
                          backgroundColor: '#FFFFFF',
                          justifyContent: 'center',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '50px'
                          }}
                      >
                        <Text tid="social.firstname" />
                      </Label>
                      </div>
                      <Field
                        onChange={handleFirstName}
                        className={`form-control`}
                        style={{
                          height: "30px",
                          width: "100%",
                          color: "#000000",
                          zIndex:1,
                          position: 'relative'
                        }}
                        name="firstname"
                        type="firstname"
                        placeholder={props.first_name  === undefined ? '' : `${props.first_name}`}
                        // component={PasswordShowHide}
                      />
                      <div style={{ fontSize: "10px", marginLeft: "6px", marginTop:"-10px", color: errorName? ("red") : ("green")}}>
                      {errorName === true ? (errorNotificationName(language)) : null}
                      {successFirstName === true ? (successNotificationFirstName(language)) : null}
                      </div>
                    </FormGroup>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'7px', position: 'relative', zIndex:10, width: '60px', marginTop: errorName || successFirstName ? '15px' : "20px"}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          marginTop: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          justifyContent: 'left',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '80px'
                          }}
                      >
                        <Text tid="social.lastname" />
                      </Label>
                      </div>
                      <Field
                        onChange={handleLastName}
                        className={`form-control`}
                        style={{
                          height: "30px",
                          width: "100%",
                          color: "#000000",
                          zIndex:1,
                          position: 'relative'
                        }}
                        name="lastname"
                        type="lastname"
                        placeholder={props.last_name === undefined ? '' : `${props.last_name}`}
                        // component={PasswordShowHide}
                      />
                      <div style={{ fontSize: "10px", marginLeft: "6px", marginTop:"-10px", color: errorName? ("red") : ("green")}}>
                      {errorName === true ? (errorNotificationName(language)) : null}
                      {successLastName === true ? (successNotificationLastName(language)) : null}
                      </div>
                    </FormGroup>
                    <div style={{ marginTop: errorName || successLastName || successFirstName ? "15px" : "30px" }}>
                    <Button
                      onClick= {resetUsername}
                      className=''
                      size="lg"
                      style={{
                        width: "100px",
                        display: "flex",
                        marginBottom: "20px",
                        margin: 'auto',
                        backgroundColor: "#eb502e",
                        border: "none",
                        borderRadius: "20px"
                      }}
                    >
                      <span style={{ margin: "auto" }} className="label">
                        <Text tid="social.reset" />
                      </span>
                    </Button>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                    <span style={{ marginLeft: '55px', fontWeight: '100' }}><Text tid="social.title-Company" /></span>
                    <div
                      className=""
                      style={{
                        borderTop: "1px solid #000000",
                        marginLeft: '65px',
                        width: "400px", 
                        margin: "auto",
                        backgroundColor: "#FFFFFF"
                      }}
                    />
                </div>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'5px', position: 'relative', zIndex:10, width: '55px', marginTop: '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          marginTop: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: resetsiret !== '' ? '#d3d3d3' : '#000000',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '55px'
                          }}
                      >
                        <Text tid="social.entreprise" />
                      </Label>
                      </div>
                      <Field
                        disabled={resetsiret !== '' ? true : false}
                        onChange={(e)=> handleEntreprise(e)}
                        className={`form-control`}
                        style={{ height: "30px", width: "100%", color: "#000000", zIndex:1, position: 'relative' }}
                        name="firstname"
                        type="text"
                        placeholder={entreprise === '' ? `${company}`: entreprise}
                      />
                      <div style={{ fontSize: "10px", marginLeft: "6px", marginTop:"-10px", marginBottom: errorEntrepriseEmpty ? "10px" : "0px", color: "red" }}>
                      {errorEntrepriseEmpty === true ? (errorNotificationSiretEmpty(language)) : null}
                      </div>
                    </FormGroup>
                    <Row>
                      <Col>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                      <div style={{ backgroundColor: '#FFFFFF', marginLeft:'81px', position: 'relative', zIndex:10, width: '90px', marginTop: "-5px"}}>
                          <Label
                            style={{
                              fontSize: "10px",
                              marginTop: "10px",
                              height: '15px',
                              color: "#787878",
                              backgrounColor: '#FFFFFF',
                              display: 'flex',
                              position: 'relative',
                              zIndex:10,
                              width: '90px'
                              }}
                          >
                            <Text tid="social.siret2" />
                          </Label>
                          </div>
                          <Field
                            disabled={resetentreprise !== '' ? true : false}
                            onChange={(e) => handleSiret(e)}
                            className={`form-control`}
                            style={{ height: "30px", width: "250px", color: "#000000", zIndex:1, position: 'relative', marginLeft: '76px' }}
                            name="firstname"
                            type="text"
                            placeholder={entreprise !== '' ? entreprise : siret === null ? 'none' : `${siret}`}
                            // component={PasswordShowHide}
                          />
                          <div style={{ fontSize: "10px", marginLeft: "81px", marginTop: "-10px", color: successSiret? ("green") : ("red")}}>
                          {errorSiretString === true ? (errorNotificationSiretString(language)) : null}
                          {errorSiretEmpty === true ? (errorNotificationSiretEmpty(language)) : null}
                          </div>
                        </FormGroup>
                        </Col>
                        <Col>
                        <div style={{ marginTop: '15px', marginLeft: '-40px' }}>
                        <Button
                          disabled={resetentreprise !== '' ? true : false}
                          onClick= {checkSiret}
                          className=''
                          size="lg"
                          style={{
                            width: "80px",
                            marginTop: '28px',
                            marginLeft: '-50px',
                            color: '#000000',
                            margin: 'auto',
                            backgroundColor: "#FFFFFF",
                            border: "none",
                            borderRadius: "20px"
                          }}
                        >
                      <span style={{ margin: "auto" }} className="label">
                      <Text tid="social.validationsiret" />
                      </span>
                    </Button>
                      </div>
                    </Col>
                    </Row>
                    <div style={{ fontSize: "10px", marginLeft: "83px", color: successSiret? ("green") : ("red")}}>
                      {successSiret === true ? (successNotificationSiret(language)) : null}
                      {errorSiret === true ? (errorNotificationSiret(language)) : null}
                      {noVerifSiret ? (errorNotificationNoVerifSiret(language)) : null}
                    </div>
                    <div style={{ marginTop: "15px" }}>
                    <Button
                      onClick= {checkResetSiret}
                      className=''
                      size="lg"
                      style={{
                        width: "100px",
                        display: "flex",
                        marginBottom: "20px",
                        margin: 'auto',
                        backgroundColor: "#eb502e",
                        border: "none",
                        borderRadius: "20px"
                      }}
                    >
                      <span style={{ margin: "auto" }} className="label">
                        <Text tid="social.reset" />
                      </span>
                    </Button>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                    <span style={{ marginLeft: '55px', fontWeight: '100' }}><Text tid="social.title-Password" /></span>
                    <div
                      className=""
                      style={{
                        borderTop: "1px solid #000000",
                        marginLeft: '65px',
                        marginTop: "20px",
                        width: "400px", 
                        margin: "auto",
                        backgroundColor: "#FFFFFF"
                      }}
                    />
                </div>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'5px', position: 'relative', zIndex:10, width: '95px', marginTop: '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          marginTop: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '95px'
                          }}
                      >
                        <Text tid="social.current" />
                      </Label>
                      </div>
                      <Row>
                        <Col>
                          <Field
                            onChange={handleCurrentPassword}
                            className={`form-control`}
                            style={{ height: "30px", width: "100%", color: "#000000", zIndex:1, position: 'relative' }}
                            name="previousPassword"
                            type={passwordHideOne? ("password") : ("text")}
                            placeholder={getByPlaceholderTextCurrent(language)}
                            // component={PasswordShowHide}
                            />
                            <img
                              onClick={()=> {
                                setpasswordHideOne(!passwordHideOne)
                              }}
                              style={{
                                position: "relative",
                                zIndex:10,
                                width: "15px",
                                height: "15px",
                                marginLeft: "320px",
                                marginTop: "-85px",
                                cursor: "pointer"
                              }}
                              src="assets/images/icons/visibility.png"
                              alt="eye"
                            />
                              </Col>
                      <div style={{ fontSize: "10px", marginLeft: "5px", marginTop:"-35px", color: "red"}}>
                      {errorAllEmpty === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                      {errorCurrent === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                      </div>
                      </Row>
                    </FormGroup>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'5px', position: 'relative', zIndex:10, width: '80px',top:"-47px", marginTop: errorCurrent? '40px' : '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          height: '15px',
                          margin: 'auto',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '120px'
                          }}
                      >
                        <Text tid="social.new" />
                      </Label>
                      </div>
                      <Row>
                        <Col>
                          <Field
                            onChange={handleNewPassword}
                            className={`form-control`}
                            style={{ height: "30px", width: "100%", color: "#000000", marginTop: '-45px', zIndex:1, position: 'relative' }}
                            name="newPassword"
                            type={passwordHideTwo? ("password") : ("text")}
                            placeholder={getByPlaceholderTextNew(language)}
                            // component={PasswordShowHide}
                            />
                            <img
                              onClick={()=> {
                                setpasswordHideTwo(!passwordHideTwo)
                              }}
                              style={{
                                position: "relative",
                                zIndex:10,
                                width: "15px",
                                height: "15px",
                                marginLeft: "320px",
                                marginTop: "-85px",
                                cursor: "pointer"
                              }}
                              src="assets/images/icons/visibility.png"
                              alt="eye"
                            />
                              </Col>
                      <div style={{ fontSize: "10px", marginLeft: "5px", marginTop:"-35px", color: "red"}}>
                      {errorAllEmpty === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                      {errorNew === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                      </div>
                      </Row>
                    </FormGroup>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'5px', position: 'relative', zIndex:10, width: '140px',top:"-47px", marginTop: errorNew? '40px' : '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          height: '15px',
                          margin: 'auto',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '180px'
                          }}
                      >
                        <Text tid="social.confirm" />
                      </Label>
                      </div>
                      <Row>
                        <Col>
                          <Field
                            onChange={handlePasswordConfirm}
                            className={`form-control`}
                            style={{ height: "30px", width: "100%", color: "#000000", marginTop: '-45px', zIndex:1, position: 'relative' }}
                            name="PasswordConfirm"
                            type={passwordHideThree? ("password") : ("text")}
                            placeholder={getByPlaceholderTextConfirm(language)}
                            // component={PasswordShowHide}
                          />
                        <img
                          onClick={()=> {
                            setpasswordHideThree(!passwordHideThree)
                          }}
                          style={{
                            position: "relative",
                            zIndex:10,
                            width: "15px",
                            height: "15px",
                            marginLeft: "320px",
                            marginTop: "-85px",
                            cursor: "pointer"
                          }}
                          src="assets/images/icons/visibility.png"
                          alt="eye"
                        />
                          </Col>
                          <div style={{ fontSize: "10px", marginLeft: "5px", marginTop:"-35px", color: successPassword? ("green") : ("red")}}>
                            {errorAllEmpty === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                            {errorConfirm === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                            {errorNotSame === true ? (errorNotificationNotSame(language)) : null}
                            {errorCurrentPassword === true ? (errorNotificationCurrentPassword(language)) : null}
                            {badPasswordType === true ? (errorNotificationPasswordType(language)) : null}
                            {successPassword === true ? (successNotificationPassword(language)) : null}
                          </div>
                          <div style={{ marginTop: errorAllEmpty || errorConfirm || errorNotSame || successPassword || errorCurrentPassword || badPasswordType ? "0px" : "-20px", marginBottom: '30px' }}>
                            <Button
                              onClick= {checkData}
                              className=''
                              size="lg"
                              style={{
                                width: "100px",
                                display: "flex",
                                marginBottom: "20px",
                                margin: 'auto',
                                backgroundColor: "#eb502e",
                                border: "none",
                                borderRadius: "20px"
                              }}
                            >
                            <span style={{ margin: "auto" }} className="label">
                              <Text tid="social.reset" />
                            </span>
                          </Button>
                          </div>
                      </Row>
                    </FormGroup>
              </Form>
            </Formik>
            </MediaQuery>
            <MediaQuery maxWidth={780}>
            <Formik 
               style={{ backgroundColor: "#fdf8f4" }}
               onSubmit={checkData}
             >
              <Form
                style={{
                  maxWidth: "350px",
                  minHeight: "1050px",
                  height: 'auto',
                  maxHeight: '1500px',
                  marginBottom: '9%',
                  color: "black",
                  background: "#FFFFFF"
                }}
                className={`av-tooltip tooltip-label-bottom ${classes.table}`}
              >
                <ListGroupItem className={classes.title}><Text tid="social.title" /></ListGroupItem>

                <div style={{ margin: 'auto', textAlign: 'center', lineHeight: '2.6', fontSize: '1rem', fontWeight: '500' }}>
                  <span>{`${props.first_name} ${props.last_name}` }</span><br />
                  <span>{getCurrentUser().email}</span><br />
                  {
                  getCurrentUser() !== null ? (getCurrentUser().permission_level === 1 ? (
                    <Badge
                    style={{ border:'2px solid red', color: 'red', fontWeight: '500', borderRadius: '20px'}}
                      color="#000000"
                      href="#"
                    >
                      PREMIUM
                  </Badge>
                  ) : (
                    <Badge
                      style={{ border:'2px solid red', color: 'red', fontWeight: '500', borderRadius: '20px'}}
                      color="#000000"
                      href="#"
                    >
                      MEMBRE
                  </Badge>
                  )) : null
                }
                </div>
                <div>
                    <span style={{ marginLeft: '55px', fontWeight: '100' }}><Text tid="social.title-Name" /></span>
                    <div
                      className=""
                      style={{
                        borderTop: "1px solid #000000",
                        marginLeft: '65px',
                        width: "280px", 
                        margin: "auto",
                        backgroundColor: "#FFFFFF"
                      }}
                    />
                </div>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'35px', position: 'relative', zIndex:10, width: '60px', marginTop: '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          marginTop: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '60px'
                          }}
                      >
                        <Text tid="social.firstname" />
                      </Label>
                      </div>
                      <Field
                        onChange={handleFirstName}
                        className={`form-control`}
                        style={{ height: "30px", width: "300px", color: "#000000", zIndex:1, position: 'relative', margin: 'auto' }}
                        name="firstname"
                        type="firstname"
                        placeholder={`${props.first_name}`}
                        // component={PasswordShowHide}
                      />
                      <div style={{ fontSize: "10px", marginLeft: "33px", marginTop:"5px", color: errorName? ("red") : ("green")}}>
                      {errorName === true ? (errorNotificationName(language)) : null}
                      {successFirstName === true ? (successNotificationFirstName(language)) : null}
                      </div>
                    </FormGroup>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'35px', position: 'relative', zIndex:10, width: '60px', marginTop: '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          marginTop: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '90px'
                          }}
                      >
                        <Text tid="social.lastname" />
                      </Label>
                      </div>
                      <Field
                        onChange={handleLastName}
                        className={`form-control`}
                        style={{ height: "30px", width: "300px", color: "#000000", zIndex:1, position: 'relative', margin: 'auto' }}
                        name="lastname"
                        type="lastname"
                        placeholder={`${props.last_name}`}
                        // component={PasswordShowHide}
                      />
                      <div style={{ fontSize: "10px", marginLeft: "33px", marginTop:"5px", marginBottom: errorName || successLastName ? "-20px" : "-5px", color: errorName? ("red") : ("green")}}>
                      {errorName === true ? (errorNotificationName(language)) : null}
                      {successLastName === true ? (successNotificationLastName(language)) : null}
                      </div>
                    </FormGroup>
                    <div style={{ marginTop: "30px" }}>
                    <Button
                      onClick= {resetUsername}
                      className=''
                      size="lg"
                      style={{
                        width: "100px",
                        display: "flex",
                        margin: 'auto',
                        backgroundColor: "#eb502e",
                        border: "none",
                        borderRadius: "20px"
                      }}
                    >
                      <span style={{ margin: "auto" }} className="label">
                        <Text tid="social.reset" />
                      </span>
                    </Button>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                    <span style={{ marginLeft: '55px', fontWeight: '100' }}><Text tid="social.title-Company" /></span>
                    <div
                      className=""
                      style={{
                        borderTop: "1px solid #000000",
                        marginLeft: '65px',
                        width: "280px", 
                        margin: "auto",
                        backgroundColor: "#FFFFFF"
                      }}
                    />
                </div>
                    <FormGroup className={`form-group has-float-label ${classes.input}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'35px', position: 'relative', zIndex:10, width: '55px', marginTop: '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          marginTop: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: resetsiret !== '' ? '#d3d3d3' : '#000000',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '55px'
                          }}
                      ><Text tid="social.entreprise" />
                      </Label>
                      </div>
                      <Field
                        disabled={resetsiret !== '' ? true : false}
                        onChange={(e)=> handleEntreprise(e)}
                        className={`form-control`}
                        style={{ height: "30px", width: "300px", color: "#000000", zIndex:1, position: 'relative', margin: 'auto' }}
                        name="firstname"
                        type="text"
                        placeholder={entreprise === '' ? `${company}`: entreprise}
                      />
                      <div style={{ fontSize: "10px", marginLeft: "33px", marginTop:"5px", color: "red" }}>
                      {errorEntrepriseEmpty === true ? (errorNotificationSiretEmpty(language)) : null}
                      </div>
                    </FormGroup>
                    <Row>
                      <Col>
                    <FormGroup className={`form-group has-float-label`}>
                      <div style={{ backgroundColor: '#FFFFFF', marginLeft:'35px', position: 'relative', zIndex:10, width: '55px', marginTop: '15px'}}>
                          <Label
                            style={{
                              fontSize: "10px",
                              marginTop: "10px",
                              height: '15px',
                              color: "#787878",
                              backgrounColor: '#FFFFFF',
                              display: 'flex',
                              position: 'relative',
                              zIndex:10,
                              width: '90px'
                              }}
                          >
                            <Text tid="social.siret2" />
                          </Label>
                          </div>
                          <Field
                            disabled={resetentreprise !== '' ? true : false}
                            onChange={(e) => handleSiret(e)}
                            className={`form-control`}
                            style={{
                              height: "30px",
                              width: "200px",
                              color: "#000000",
                              zIndex:1,
                              position: 'relative',
                              marginLeft: '25px'
                            }}
                            name="firstname"
                            type="text"
                            placeholder={entreprise === '' ? `${siret}` : entreprise}
                            // component={PasswordShowHide}
                          />
                        </FormGroup>
                        </Col>
                        <Col>
                        <div style={{ marginLeft: '-10px' }}>
                        <Button
                          onClick= {checkSiret}
                          className=''
                          size="lg"
                          style={{
                            width: "80px",
                            marginTop: '25px',
                            color: '#000000',
                            backgroundColor: "#FFFFFF",
                            border: "none",
                            borderRadius: "20px"
                          }}
                        >
                      <span style={{ margin: "auto" }} className="label">
                        <Text tid="social.validationsiret" />
                      </span>
                    </Button>
                      </div>
                    </Col>
                    </Row>
                    <div
                      style={{
                        fontSize: "10px",
                        marginLeft: "33px",
                        marginBottom: successSiret ||
                        errorSiret ||
                        noVerifSiret ||
                        errorSiretEmpty ||
                        errorSiretString ? "-5px" : "20px",
                        marginTop: "-10px",
                        color: successSiret? ("green") : ("red")}}>
                      {successSiret === true ? (successNotificationSiret(language)) : null}
                      {errorSiret === true ? (errorNotificationSiret(language)) : null}
                      {noVerifSiret ? (errorNotificationNoVerifSiret(language)) : null}
                      {errorSiretString === true ? (errorNotificationSiretString(language)) : null}
                      {errorSiretEmpty === true ? (errorNotificationSiretEmpty(language)) : null}
                    </div>
                    <div style={{ marginTop: "15px" }}>
                    <Button
                      onClick= {checkResetSiret}
                      className=''
                      size="lg"
                      style={{
                        width: "100px",
                        display: "flex",
                        marginBottom: "20px",
                        margin: 'auto',
                        backgroundColor: "#eb502e",
                        border: "none",
                        borderRadius: "20px"
                      }}
                    >
                      <span style={{ margin: "auto" }} className="label">
                        <Text tid="social.reset" />
                      </span>
                    </Button>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                    <span style={{ marginLeft: '55px', fontWeight: '100' }}><Text tid="social.title-Password" /></span>
                    <div
                      className=""
                      style={{
                        borderTop: "1px solid #000000",
                        marginLeft: '65px',
                        marginTop: "20px",
                        width: "280px", 
                        margin: "auto",
                        backgroundColor: "#FFFFFF"
                      }}
                    />
                </div>
                    <FormGroup className={`form-group has-float-label ${classes.inputResponsive}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'35px', position: 'relative', zIndex:10, width: '95px', marginTop: '20px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          marginTop: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '95px'
                          }}
                      >
                        <Text tid="social.current" />
                      </Label>
                      </div>
                      <Row>
                        <Col>
                          <Field
                            onChange={handleCurrentPassword}
                            className={`form-control`}
                            placeholder={getByPlaceholderTextCurrent(language)}
                            style={{
                              height: "30px",
                              width: "300px",
                              color: "#000000",
                              zIndex:1,
                              position: 'relative',
                              margin: 'auto'
                            }}
                            name="previousPassword"
                            type={passwordHideOne? ("password") : ("text")}
                            // component={PasswordShowHide}
                            />
                            <img
                              onClick={()=> {
                                setpasswordHideOne(!passwordHideOne)
                              }}
                              style={{
                                position: "relative",
                                zIndex:10,
                                width: "15px",
                                height: "15px",
                                marginLeft: "300px",
                                marginTop: "-55px",
                                cursor: "pointer"
                              }}
                              src="assets/images/icons/visibility.png"
                              alt="eye"
                            />
                              </Col>
                            <div style={{ fontSize: "10px", width: "300px", marginLeft: "33px", marginTop:"-20px", color: successPassword? ("green") : ("red")}}>
                              {errorAllEmpty === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                              {errorCurrent === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                            </div>
                      </Row>
                    </FormGroup>
                    <FormGroup className={`form-group has-float-label ${classes.inputResponsive}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'35px', position: 'relative', zIndex:10, width: '80px',top:"0px", marginTop: errorCurrent? '10px' : '-5px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '120px'
                          }}
                      >
                        <Text tid="social.new" />
                      </Label>
                      </div>
                      <Row>
                        <Col>
                          <Field
                            onChange={handleNewPassword}
                            className={`form-control`}
                            style={{ height: "30px", width: "300px", color: "#000000", zIndex:1, position: 'relative', margin: 'auto' }}
                            name="newPassword"
                            type={passwordHideTwo? ("password") : ("text")}
                            placeholder={getByPlaceholderTextNew(language)}
                            // component={PasswordShowHide}
                            />
                            <img
                              onClick={()=> {
                                setpasswordHideTwo(!passwordHideTwo)
                              }}
                              style={{
                                position: "relative",
                                zIndex:10,
                                width: "15px",
                                height: "15px",
                                marginLeft: "300px",
                                marginTop: "-55px",
                                cursor: "pointer"
                              }}
                              src="assets/images/icons/visibility.png"
                              alt="eye"
                            />
                              </Col>
                          <div style={{ fontSize: "10px", width: "300px", marginLeft: "33px", marginTop:"-20px", color: successPassword? ("green") : ("red")}}>
                            {errorAllEmpty === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                            {errorNew === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                          </div>
                      </Row>
                    </FormGroup>
                    <FormGroup className={`form-group has-float-label ${classes.inputResponsive}`}>
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft:'35px', position: 'relative', zIndex:10, width: '120px',top:"0px", marginTop: errorNew? '10px' : '-5px'}}>
                      <Label
                        style={{
                          fontSize: "10px",
                          height: '15px',
                          color: "#787878",
                          backgrounColor: '#FFFFFF',
                          display: 'flex',
                          position: 'relative',
                          zIndex:10,
                          width: '180px'
                          }}
                      >
                        <Text tid="social.confirm" />
                      </Label>
                      </div>
                      <Row>
                        <Col>
                          <Field
                            onChange={handlePasswordConfirm}
                            className={`form-control`}
                            style={{ height: "30px", width: "300px", color: "#000000", zIndex:1, position: 'relative', margin: 'auto' }}
                            name="PasswordConfirm"
                            type={passwordHideThree? ("password") : ("text")}
                            placeholder={getByPlaceholderTextConfirm(language)}
                            // component={PasswordShowHide}
                          />
                        <img
                          onClick={()=> {
                            setpasswordHideThree(!passwordHideThree)
                          }}
                          style={{
                            position: "relative",
                            zIndex:10,
                            width: "15px",
                            height: "15px",
                            marginLeft: "300px",
                            marginTop: "-55px",
                            cursor: "pointer"
                          }}
                          src="assets/images/icons/visibility.png"
                          alt="eye"
                        />
                          </Col>
                          <div
                            style={{
                              width: "300px",
                              fontSize: "10px",
                              marginLeft: "33px",
                              marginTop:"-20px",
                              marginBottom: errorAllEmpty ||
                                errorConfirm ||
                                errorNotSame ||
                                errorCurrentPassword ||
                                badPasswordType ||
                                successPassword ? "15px" : "0px",
                              color: successPassword? ("green") : ("red")
                            }}
                          >
                            {errorAllEmpty === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                            {errorConfirm === true ? (errorNotificationAllFieldsEmpty(language)) : null}
                            {errorNotSame === true ? (errorNotificationNotSame(language)) : null}
                            {errorCurrentPassword === true ? (errorNotificationCurrentPassword(language)) : null}
                            {badPasswordType === true ? (errorNotificationPasswordType(language)) : null}
                            {successPassword === true ? (successNotificationPassword(language)) : null}
                          </div>
                          <div style={{ marginBottom: '30px' }}>
                            <Button
                              onClick= {checkData}
                              className=''
                              size="lg"
                              style={{
                                width: "100px",
                                display: "flex",
                                marginBottom: "20px",
                                margin: 'auto',
                                backgroundColor: "#eb502e",
                                border: "none",
                                borderRadius: "20px"
                              }}
                            >
                            <span style={{ margin: "auto" }} className="label">
                              <Text tid="social.reset" />
                            </span>
                          </Button>
                          </div>
                      </Row>
                    </FormGroup>
              </Form>
            </Formik>
            </MediaQuery>
        </div>
         </div>
         <MediaQuery maxWidth={780}>
          <div className={classes.footerResponsive}>
          <Footer />
          </div>
          </MediaQuery>
         <MediaQuery minWidth={780}>
         <MediaQuery minWidth={1025}>
          <div className={classes.footer}>
          <Footer />
          </div>
          </MediaQuery>
          <MediaQuery maxWidth={1025}>
          <div className={classes.footerTab}>
          <Footer />
          </div>
          </MediaQuery>
          </MediaQuery>
         </div>
      </section>
    </React.Fragment>
  );
};

export default Example;