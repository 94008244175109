import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import axios from 'axios';

import { Text } from '../../containers/Language';

import { backLink } from '../../constants/defaultValues';

// import LanguageSelector from '../../components/LanguageSelector';

// import { Form, Field, Formik } from 'formik';

// import { AvForm, AvField } from "availity-reactstrap-validation";

 const PaySuccess = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [language, setlanguage] = useState('');
  
  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken")
    axios({
      method: 'patch',
      url: `${backLink}/clients/premium`,
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    })
    .then(function (response) {
      //console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
      window.location.href = "/dashboard"
    });
    // console.log('getpay', props.getPay)
   setTimeout(() => {
      window.location.href = "/dashboard"
    }, 6000)
  }, [props])
  return (
    <React.Fragment>
      <div className="account-home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="mdi mdi-home h1"></i>
        </Link>
      </div>

      <section className="bg-account-pages vh-100">
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5}>
                  <Card 
                    className="account-card"
                    style={{ width: "500px" }}
                  >
                    <CardBody>
                      <div className="text-center mt-3 mb-1">
                        <h1>
                          <Link
                            to="/"
                            style={{ color: "#EB502E" }}
                            className="text-dark text-uppercase account-pages-logo"
                          > 
                            <span style={{color: "#EB502E" }}><Text tid="signup.title" /></span>
                          </Link>
                          </h1>
                        <h2 className="font-weight-bold">
                        <Text tid="payment.text" />
                        </h2>
                        <h3 className="font-weight-bold mb-2"><Text tid="payment.thankyou" /></h3>
                        <Text tid="payment.subtext" />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default PaySuccess;
