import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import ScrollspyNav from "./scrollSpy";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import { backLink} from '../../constants/defaultValues';
//stickey header
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";
// import { Link } from "react-router-dom";

import { Text } from '../../containers/Language';

import LanguageSelectorNavbar from '../../components/LanguageSelectorNavbar';

import MediaQuery from 'react-responsive';

import { getCurrentUser } from '../../helpers/Utils';

import { delete_cookie } from 'sfcookies';

const useStyles = makeStyles((theme) => ({
  logo: {
      height: 35,
      width: "110.63px",
      margin: 0,
      marginLeft: "10%",
  },
  logoResponsive: {
      height: 40,
      width: "100%",
      margin: 0,
      marginLeft: "10%",
      marginRight: "10px"
  },
  NavBarStyle: {
    backgroundColor: "#fdf8f4",
    color: "#0c1d37",
    width: "100%"
  },
  boxContent: {
    backgroundColor: "#fdf8f4",
    color: "#0c1d37",
    width: "100%",
    margin: "auto"
  },
  BoxTitle: {
    width: "50%",
    justifyContent: "center",
  },
  BoxTitleResponsive: {
    width: "50%",
    justifyContent: "right",
    display: 'flex'
  },
  toggleStyle: {
    color: "black"
  },
  ButtonLogin: {
    color: "#0c1d37",
    backgroundColor: "#fdf8f4",
    border: "2px solid",
    width: "200px"
  }
}));

function NavbarPage(props) {
  // const [userId, setuserId] = useState("");
  // const [username, setusername] = useState("");
  // const [email, setemail] = useState("");
  const [login, setlogin] = useState(false);
  let history = useHistory();
  
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  // let isOpenMenu = false
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  
  const open = Boolean(anchorEl);
  
  useEffect(() => {
    // console.log('props.login', props.login);
    // console.log('login', login);
    if (props.login === false) {
      if(getCurrentUser() !== null) {
        setlogin(true);
        props.setlogin(true);
      } else {
        setlogin(false);
        props.setlogin(false);
      }
    }
  }, [props, login])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const consoleLog = () => {
  //   console.log("props", props);
  // }
  // consoleLog();
  const handleClose = () => {
    history.push('/profil')
    setAnchorEl(null);
  };
  // const redirectProfil = () => {
  //   setAnchorEl(null);
  // };

  const toggle = () => {
    setIsOpenMenu(!isOpenMenu)
  };
  const redirectLogin = () => {
    history.push('/login')
  }
  const redirectProfil = () => {
    history.push('/profil')
  }
  const redirectSignUp = () => {
    history.push('/sign-up')
  }
  const redirectSwagger = () => {
    history.push('/documentation')
  }
  const redirectDashboard = () => {
    history.push('/dashboard')
  }
  /* const handlelocal = () => {
    console.log('username', username);
    console.log('userId', userId);
    console.log('email', email);
    console.log('login', login);
    console.log("backlink", backLink);
  } */
  const clearLocalStorage = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('client_id');
    localStorage.removeItem('permission_level');
    localStorage.removeItem('current_user');
    localStorage.removeItem('confirmToken');
    localStorage.removeItem('tokenExp');
    setlogin(false);
    delete_cookie('refreshToken')
    localStorage.setItem('login', false);
    history.push('/login');
    setAnchorEl(null);
  }
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let targetId = props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        <StickyHeader
          header={
            <Navbar
              expand="lg"
              fixed="top"
              style={{ backgroundColor: "#fdf8f4", color: "#0c1d37", width: "100%" }}
              className={
                "navbar-custom sticky sticky-dark " + props.navClass
              }
            >
              <Container className={classes.boxContent}>
                <LanguageSelectorNavbar setlanguage={props.setlanguage} />
                <MediaQuery minWidth={980}>
                  <NavbarBrand className="logo text-uppercase" style={{ cursor: "pointer"}} onClick={() => { history.push('/') }}>
                    <img src="../assets/images/logo/ipocamp.png" className={classes.logo} alt="logo" />
                  </NavbarBrand>
                </ MediaQuery>
                <MediaQuery maxWidth={980}>
                  <NavbarBrand className="logo text-uppercase" style={{ cursor: "pointer"}} onClick={() => { history.push('/') }}>
                    <img src="../assets/images/logo/ipocamp.png" className={classes.logoResponsive} alt="logo" />
                  </NavbarBrand>
                </ MediaQuery>
                <NavbarToggler className={classes.toggleStyle} onClick={toggle}>
                  <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse
                  id="navbarCollapse"
                  isOpen={isOpenMenu}
                  navbar
                >
                  <ScrollspyNav
                    scrollTargetIds={targetId}
                    scrollDuration="800"
                    headerBackground="true"
                    activeNavClass="active"
                    className="navbar-collapse"
                  >
                    <Nav navbar className={classes.boxTitle + "navbar-center"} id="mySidenav">
                      {props.navItems.map((item, key) => (
                        <NavItem
                          key={key}
                          className={item.navheading === "Home" ? "active" : ""}
                        >
                          <NavLink href={"#" + item.idnm} 
                            style={{ color: "#0c1d37", lineHeight : "8px" }}>
                            {" "}
                            {item.navheading}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <div className="nav-button ms-auto">
                      {
                        props.login === false ? (
                      <div>
                      <MediaQuery minWidth={975}>
                      <Nav navbar className="navbar-end">
                      <Button
                          style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "none", width: "180px", marginLeft: "10px" }}
                          type="button"
                          className="btn navbar-btn btn-rounded waves-effect waves-light"
                          onClick={redirectSwagger}
                          // onClick={handleLog}
                        >
                          Documentation API
                      </Button>
                        <Button
                            style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "none", width: "200px" }}
                            type="button"
                            className="btn navbar-btn btn-rounded waves-effect waves-light"
                            onClick={redirectSignUp}
                            // onClick={handleLog}
                          >
                            <Text tid="navbar.signup" />
                          </Button>
                          <Button
                            style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "2px solid", width: "200px" }}
                            type="button"
                            className={classes.ButtonLogin + "btn navbar-btn btn-rounded waves-effect waves-light"}
                            onClick={redirectLogin}
                            // onClick={handleLog}
                          >
                            <Text tid="navbar.login" />
                          </Button>
                      </Nav>
                      </MediaQuery>
                      <MediaQuery maxWidth={975}>
                        <div style={{ display: 'flex', justifyContent: 'left', marginTop: '7px'}}>
                          <ul>
                          <p style={{ cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectSwagger}>API</p>
                          <p style={{ cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectSignUp}><Text tid="navbar.signup" /></p>
                          <p style={{ cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectLogin}><Text tid="navbar.login" /></p>
                          </ul>
                        </div>
                      </MediaQuery>
                      </div>
                      ) : (
                        <Nav navbar className="navbar-end">
                        <MediaQuery minWidth={975}>
                          <Button
                              style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "none", width: "250px", margin: "auto", }}
                              type="button"
                              className="btn navbar-btn btn-rounded waves-effect waves-light"
                              onClick={redirectSwagger}
                              // onClick={handleLog}
                            >
                              Documentation API
                          </Button>
                          <Button
                            style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "2px solid", width: "200px" }}
                            className={classes.ButtonLogin + "btn navbar-btn btn-rounded waves-effect waves-light"}
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                          >
                            {`${getCurrentUser().first_name} ${getCurrentUser().last_name} `}
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={redirectDashboard}>Dashboard</MenuItem>
                            <MenuItem onClick={redirectProfil}><Text tid="navbar.profil" /></MenuItem>
                            <MenuItem onClick={clearLocalStorage}>Logout</MenuItem>
                          </Menu>
                          </ MediaQuery>
                          <MediaQuery maxWidth={975}>
                            <div style={{ display: 'flex', justifyContent: 'left', marginTop: '-10px'}}>
                              <ul>
                              <p style={{ marginBottom: "15px", marginTop: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectSwagger}>API</p>
                              <p style={{ marginBottom: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectDashboard}>Dashboard</p>
                              <p style={{ marginBottom: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectProfil}><Text tid="navbar.profil" /></p>
                              <p style={{ marginBottom: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={clearLocalStorage}><Text tid="navbar.logout" /></p>
                              </ul>
                            </div>
                          </MediaQuery>
                        </Nav>
                      )
                    }
                    </div>
                  </ScrollspyNav>
                </Collapse>
              </Container>
            </Navbar>
          }
          stickyOffset={-100}
        ></StickyHeader>
      </React.Fragment>
    );
  }

export default NavbarPage;
