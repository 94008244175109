import React, { Component } from "react";
import { Container, Row } from "reactstrap";

import { Text } from '../../containers/Language';

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-timer",
          title: <Text tid="service.encart-title1" />,
          desc: <Text tid="service.encart-text1" />,
        },
        {
          icon: "pe-7s-file",
          title: <Text tid="service.encart-title2" />,
          desc: <Text tid="service.encart-text2" />,
        },
        {
          icon: "pe-7s-door-lock",
          title: <Text tid="service.encart-title3" />,
          desc: <Text tid="service.encart-text3" />,
        },
      ],
      services2: [
        {
          icon: "pe-7s-map",
          title: <Text tid="service.encart-title4" />,
          desc: <Text tid="service.encart-text4" />,
        },
        {
          icon: "pe-7s-loop",
          title: <Text tid="service.encart-title5" />,
          desc: <Text tid="service.encart-text5" />,
        },
        {
          icon: "pe-7s-close-circle",
          title: <Text tid="service.encart-title6" />,
          desc: <Text tid="service.encart-text6" />,
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="pt-5" id="services" style={{ backgroundColor: "#fdf8f4" }}>
          <Container>
            {/* section title */}
            <SectionTitle
              title={<Text tid="service.title" />}
              // desc={<Text tid="service.subtitle" />}
            />

            <Row className="mt-1">
              {/* services box */}
              <ServiceBox services={this.state.services1} />
            </Row>

            <Row className="mt-1">
              {/* service box */}
              <ServiceBox services={this.state.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
