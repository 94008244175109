import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import { Text } from '../../containers/Language';

import LanguageSelector from '../../components/LanguageSelector';

import { useHistory } from 'react-router-dom';

const ErrorNotFound = () => {

    let history = useHistory();

    const [blackButton, setblackButton] = useState("#FFFFFF");
    const [language, setlanguage] = useState();
  // eslint-disable-next-line no-unused-vars

  return (
    <React.Fragment>
      <div className="account-home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="mdi mdi-home h1"></i>
        </Link>
      </div>

      <section className="bg-account-pages vh-100">
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5}>
                  <Card className="account-card">
                    <CardBody>
                    <LanguageSelector setlanguage={setlanguage} />
                      <div className="text-center mt-3">
                        <h3 className="font-weight-bold">
                          <Link
                            to="/"
                            style={{ color: "#eb502e"}}
                            className="text-uppercase account-pages-logo"
                          >
                            <img
                              src="../assets/images/logo/ipocamp.png"
                              style={{          
                                height: 35,
                                width: "110.63px",
                                margin: 0,
                              }}
                              alt="logo"
                            />
                          </ Link>
                        </h3>
                        <h4 className="mt-3">
                          <Text tid="error.subtitle" />
                        </h4>
                      </div>
                      <div
                        className="p-3"
                      >
                      <p
                        className="text-muted"
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                      >
                        <Text tid="error.code" />
                      </p>
                      <h1 style={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontSize: '60px',
                      }}>
                          404
                      </h1>
                      <Button
                            style={{
                            backgroundColor:"#eb502e",
                            border: "none",
                            display: "flex",
                            margin: "auto",
                            width: "auto",
                            marginTop: "50px",
                            marginBottom:"100px",
                            color: blackButton
                            }}
                            onMouseOver={() => setblackButton('#000000')}
                            onMouseLeave={() => setblackButton("#FFFFFF")}
                            // onClick={() => history.push("/#" + props.navItems[2].idnm)}
                            onClick={() => history.push('/')}
                       >
                        <Text tid="error.redirect" />
                       </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ErrorNotFound;
