/* eslint-disable */
import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Row, Label } from "reactstrap";
import { Colxx } from '../../components/common/CustomBootstrap';
import { Text } from '../../containers/Language';
//Import Footer link
// import FooterLinks from "./footer-links";
// import Switcher from "./Switcher";

class Footer extends Component {
  render() {
    return (
      <footer className="page-footer">
      <div
        className=""
        style={{
          borderTop: "1px solid #d3d3d3",
          backgroundColor:"#fdf8f4",
        }}
      />
      <div 
        style={{
          paddingTop: "2.2rem",
          paddingBottom: "2.2rem",
          width: "100%",
          paddingLeft: "2.2rem"
        }}
      >
        <div className="container-fluid">
          <Row>
            <Colxx xxs="12" sm="6">
              <Label>
                ©2022,&nbsp; 
                <a className="btn-link">
                  <Label style={{ color: "#eb502e"}}>
                    IPOCAMP
                  </Label>
                </a>
                &nbsp;<Text tid="footer.copyright" />
              </Label>
            </Colxx>
          </Row>
        </div>
      </div>
    </footer>
    );
  }
}

export default Footer;
