import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import axios from 'axios';

import { backLink } from '../../constants/defaultValues';

import { useHistory } from 'react-router-dom';

import { AvForm, AvField } from "availity-reactstrap-validation";

import { Text } from '../../containers/Language';

import LanguageSelector from '../../components/LanguageSelector';

const ResetPassword = () => {
  const [token, settoken] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [verifyPassword, setverifyPassword] = useState('');
  const [error, seterror] = useState(false);
  const [language, setlanguage] = useState("");

  let history = useHistory();
  
  const resetPassword = () => {
    if(newPassword === verifyPassword) {
      axios({
        method: 'patch',
        url: `${backLink}/clients/reset-password`,
        headers: { 
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          password: newPassword,
        }),
      })
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        history.push('/login');
      })
      .catch(function (error) {
        console.log("error", error);
      });
    } else {
      seterror(true);
    }
  }
  useEffect(() => {
    // console.log("token", window.location.search.split('=')[1]);
    // console.log('newPassword', newPassword);
    // console.log('verifyPassword', verifyPassword);
    settoken(window.location.search.split('=')[1]);
  }, [token, newPassword, verifyPassword]);

  const handlenewPassword = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
      setnewPassword(e.target.value);
    }
  }
  const handleverifyPassword = (e) => {
    if(e.target.value.match("[<>]=?|=")==null) {
    setverifyPassword(e.target.value);
    }
  }
  const errorNotification = () => {
    return(
     <div
       className="alert text-center"
       role="alert"
       style={{backgroundColor: '#F84F31', color: "white" }}
     >
       Your new password and the password confirm have to be the same
     </div>
   )
  }
  const placeholdernewPassword = () => {
    if(language === 'en') {
      return "enter the new password";
    } else {
      return "entrer votre nouveau mot de passe";
    }
  }
  const placeholderverifyPassword = () => {
    if(language === 'en') {
      return "confirm the new password";
    } else {
      return "confirmer le nouveau mot de passe";
    }
  }
    return (
      <React.Fragment>
        <div className="account-home-btn d-none d-sm-block">
          <Link to="/" className="text-white">
            <i className="mdi mdi-home h1"></i>
          </Link>
        </div>

        <section className="bg-account-pages vh-100">
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={5}>
                    <Card className="account-card">
                      <CardBody>
                      <LanguageSelector setlanguage={setlanguage} />
                        <div className="text-center mt-3">
                          <h3 className="font-weight-bold">
                            <a
                              href="index.html"
                              className="text-dark text-uppercase account-pages-logo"
                            >
                              <Text tid="reset.title" />
                            </a>
                          </h3>
                          <p className="text-muted"><Text tid="reset.subtitle" /></p>
                        </div>
                        <div className="p-3">
                          <div
                            className="alert alert-warning  text-center"
                            role="alert"
                          >
                            <Text tid="reset.warning" />
                          </div>
                          <AvForm>
                            <FormGroup>
                              <Label htmlFor="email"><Text tid="reset.newPassword" /></Label>
                              <AvField
                                onChange={handlenewPassword}
                                name="newPassword"
                                required
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder={placeholdernewPassword()}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="email"><Text tid="reset.verifyPassword" /></Label>
                              <AvField
                                onChange={handleverifyPassword}
                                name="verifyPassword"
                                required
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder={placeholderverifyPassword()}
                              />
                            </FormGroup>
                            { error === true ? (errorNotification()) : null}
                            <div className="d-grid mt-3">
                              <Button
                                onClick={resetPassword}
                                color="none"
                                type="submit"
                                className="bg-color-chart text-chart"
                              >
                                <Text tid="reset.validationbutton" />
                              </Button>
                            </div>
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
}

export default ResetPassword;
