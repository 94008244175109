import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  // Input,
  Button,
} from "reactstrap";

import { Text } from '../../containers/Language';
// import { backLink } from '../../constants/defaultValues';

import MediaQuery from 'react-responsive';

import LanguageSelector from '../../components/LanguageSelector';

import axios from 'axios';

import { backLink } from '../../constants/defaultValues';

import { useHistory } from 'react-router-dom';

import { Form, Field, Formik } from 'formik';

import {
  errorNotificationSiretString,
  errorNotificationNoVerifSiret
} from '../../helpers/errorNotification';

import SignupReponsive from './SignupResponsive';
// import { AvForm, AvField } from "availity-reactstrap-validation";

 const Signup = () => {

  let history = useHistory();

  
  const [language, setlanguage] = useState('');
  const [lastName, setlastName] = useState('');
  const [firstName, setfirstName] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [passwordConfirm, setpasswordConfirm] = useState('');
  const [entreprise, setentreprise] = useState('');
  const [siretName, setsiretName] = useState('');
  const [siret, setSiret] = useState(0);
  const [error, seterror] = useState(false);
  const [errorpasswordNotSame, seterrorpasswordNotSame] = useState(false);

  const [successPassword, setsuccessPassword] = useState(false);
  const [badPasswordType, setbadPasswordType] = useState(false);

  const [successSiret, setsuccessSiret] = useState(false);
  const [errorSiret, seterrorSiret] = useState(false);

  const [errorSiretEmpty, seterrorSiretEmpty] = useState(false);
  const [errorSiretString, seterrorSiretString] = useState(false);

  const [noVerifSiret, setnoVerifSiret] = useState(false);

  const [errorAllEmptyLastName, seterrorEmptyLastName] = useState(false);
  const [errorAllEmptyFirstName, seterrorEmptyFirstName] = useState(false);
  const [errorAllEmptyEmail, seterrorEmptyEmail] = useState(false);
  const [errorAllEmptyEntreprise, seterrorEmptyEntreprise] = useState(false);
  const [errorEmptyPassword, seterrorEmptyPassword] = useState(false);
  const [errorEmptySiret, seterrorEmptySiret] = useState(false);

  const [showPasswordOne, setshowPasswordOne] = useState(false);
  const [showPasswordTwo, setshowPasswordTwo] = useState(false);

  // useEffect(() => {
  //   setlanguage(localStorage.getItem('rcml-lang'))
  //   console.log('dictionnary', dictionnary);
  // }, [language])

  const handleSubmit = () => {
    const strongRegex = new RegExp("^(?=.*[A-Z])(?=.{7,})")
    // console.log('siret', typeof siret);
    if( lastName === '') {
      seterrorEmptyLastName(true);
    } else {
      seterrorEmptyLastName(false);
    }
    if (firstName === '') {
      seterrorEmptyFirstName(true)
    } else {
      seterrorEmptyFirstName(false)
    }
    if(email === '') {
      seterrorEmptyEmail(true);
    } else {
      seterrorEmptyEmail(false);
    }
    if (password === '') {
      setbadPasswordType(false);
      seterrorEmptyPassword(true);
    } else {
      seterrorEmptyPassword(false);
    }
    if (siret === 0 && entreprise === '') {
      setnoVerifSiret(true);
    } else {
      if(siret.length !== 14) {
        setnoVerifSiret(true);
      }
      if(entreprise !== '') {
        setnoVerifSiret(false);
      }
    }
    if (entreprise === '' && siretName === '') {
      seterrorEmptyEntreprise(true);
    } else {
      seterrorEmptyEntreprise(false);
    }
    if (strongRegex.test(password)){  
      setsuccessPassword(true);
      setbadPasswordType(false);
    } else {  
      setsuccessPassword(false);
      setbadPasswordType(true);
    }
    if (password !== passwordConfirm) {
      seterrorpasswordNotSame(true);
    }
    if(lastName !== '' && firstName !== '' && email !== '' && successPassword && !errorpasswordNotSame && (entreprise !== '' || siretName !== '') && (siret === 0 || siret !== 0)) {
      if(successSiret) {
        if(siret === 0) {
          userRegister();
          setnoVerifSiret(false);
        } else {
          userRegister();
          // console.log('good !')
          setnoVerifSiret(false);
        }
      } else {
        userRegister();
        setnoVerifSiret(false);
      }
    }
  }
  const userRegister = () => {
    // console.log("lastname", lastName);
    // console.log("email", email);
    // console.log("password", password);
    // console.log("siret", siret);
    let siretNumber;
    var config = {
      method: 'post',
      url: `${backLink}/clients`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : {
        //"username": username,
        "last_name": lastName,
        "first_name": firstName,
        "email": email,
        "company": siretName !== '' ? siretName : entreprise,
        "siret": siret,
        "password": password
      }
    };
    
    axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // console.log("response.data.status", response.data.status);
      if(response.data.post === "ok") {
        window.localStorage.setItem('confirmToken', response.data.confirmToken);
        history.push('/confirm');
      }
    })
    .catch(function (error) {
      console.log(error);
      seterror(true);
    });
  }
  /* const handleVariable = () => {
    console.log('email', email);
    console.log('password', password);
    console.log('username', username);
  } */  
const handleLastName = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setlastName(e.target.value);
  }
}
const handleFirstName = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setfirstName(e.target.value);
  }
}
const handleSiret = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    // console.log('siret', typeof e.target.value);
    if(e.target.value.match("[0-9]")==null) {
      // console.log('nonon')
      seterrorSiretEmpty(false);
      seterrorSiretString(true);
      seterrorEmptySiret(false);
      setsuccessSiret(false);
      seterrorSiret(false);
      setnoVerifSiret(false);
    } else {
      seterrorSiretEmpty(false);
      setsuccessSiret(false);
      seterrorSiretString(false);
      // console.log('okok')
      setSiret(e.target.value.replace(/\s/g, ''));
    }
  } else {
    // console.log('nonon')
    seterrorSiretEmpty(false);
    seterrorSiretString(true);
  }
}
const handleEntreprise = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setentreprise(e.target.value);
  }
} 
const handleEmail = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setemail(e.target.value);
  }
}
const handlePassword = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setpassword(e.target.value);
  }
}
const handlePasswordConfirm = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setpasswordConfirm(e.target.value);
  }
}
const errorNotification = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
      <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
        Your email address already exists in our database
      </div>
    )
    } else {
      return (
        <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
          Votre adresse email existe déjà dans notre base de donnée
        </div>
      )
    }
  } else {
    if(locallang === "en") {
      return(
        <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
          Your email address already exists in our database
        </div>
      )
      } else {
        return (
          
          <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-20px", color:"red"}}>
            Votre adresse email existe déjà dans notre base de donnée
          </div>
        )
      }
  }
}
// const placeholderPassword = () => {
//   if(language === 'en') {
//     return "Enter your password";
//   } else {
//     return "Entrez votre mot de passe";
//   }
// }
// const placeholderPasswordConfirm = () => {
//   if(language === 'en') {
//     return "Confirm your password";
//   } else {
//     return "Confirmez votre mot de passe";
//   }
// }
// const placeholderEntreprise = () => {
//   if(language === 'en') {
//     return "Enter your company";
//   } else {
//     return "Entrez votre le nom de votre entreprise";
//   }
// }
// const placeholderEmail = () => {
//   if(language === 'en') {
//     return "Enter your email";
//   } else {
//     return "Entrez votre adresse email";
//   }
// }
// const placeholderLastName = () => {
//   if(language === 'en') {
//     return "Enter your lastname";
//   } else {
//     return "Entrez nom de famille";
//   }
// }
// const placeholderFirstName = () => {
//   if(language === 'en') {
//     return "Enter your first name";
//   } else {
//     return "";
//   }
// }
// const placeholderSiret = () => {
//   if(language === 'en') {
//     return "Enter your SIRET number";
//   } else {
//     return "Entrez votre numéro de SIRET";
//   }
// }
const errorNotificationpassword = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "The password and password confirm are different, Please try again"
    )
    } else {
      return (
          "Le mot de passe et le mot de passe de confirmation sont différents"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "The password and password confirm are different, Please try again"
      )
      } else {
        return (
          "Le mot de passe et le mot de passe de confirmation sont différents"
        )
      }
  }
}
const errorNotificationAllFieldsEmpty = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "This field is empty"
    )
    } else {
      return (
          "Ce champ est vide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
          "This field is empty"
      )
      } else {
        return (
            "Ce champ est vide"
        )
      }
  }
}
const errorNotificationPasswordType = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "The password must contain at least one uppercase and 7 characters"
    )
    } else {
      return (
          "le mot de passe doit contenir au moins une majuscule et 7 caractères"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "The password must contain at least one uppercase and 7 characters"
      )
      } else {
        return (
          "le mot de passe doit contenir au moins une majuscule et 7 caractères"
        )
      }
  }
}
const successNotificationSiret = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "SIRET number found !"
    )
    } else {
      return (
          "Numéro de SIRET valide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "SIRET number found !"
      )
      } else {
        return (
          "Numéro de SIRET valide"
        )
      }
  }
}
const errorNotificationSiret = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "SIRET number not found !"
    )
    } else {
      return (
          "Numéro de SIRET invalide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "SIRET number not found !"
      )
      } else {
        return (
          "Numéro de SIRET invalide"
        )
      }
  }
}
const checkResetSiret = () => {
  // console.log('siret', resetSiret);
  if( siret === '' && entreprise === '') {
    seterrorSiretEmpty(true);
  } else {
      if(siret.length === 14) {
        setnoVerifSiret(false);
        seterrorSiretString(false);
        seterrorSiretEmpty(false);
        setsuccessSiret(false);
        seterrorSiret(false);
        checkSiret();
      }else {
        setnoVerifSiret(true);
        seterrorSiretString(false);
        setsuccessSiret(false);
        seterrorSiretEmpty(false);
        seterrorSiret(false);
      }
    }
}
const checkSiret = () => {
  if(siret === '' && entreprise === '') {
    console.log('siret', siret);
    seterrorSiretEmpty(true);
    setnoVerifSiret(false);
    seterrorSiretString(false);
    seterrorSiret(false);
    if(!successSiret) {
      setnoVerifSiret(true);
    }
  } else {
    if(siret.length === 14) {
      var config = {
        method: 'get',
        url: `https://api.insee.fr/entreprises/sirene/V3/siret/${siret}`,
        headers: {
          'Authorization': 'Bearer b417d363-ef5b-3a35-8cfb-e043729a5260',
          'Accept': 'application/json',
          
        }
      };
      
      axios(config)
      .then(function (response) {
        console.log(response);
        // console.log(JSON.stringify(response.data));
        // console.log(response.data.etablissement.nom_raison_sociale);
        if(response.data.etablissement.uniteLegale.denominationUniteLegale !== undefined) {
          seterrorSiret(false);
          setsuccessSiret(true);
          seterrorSiretString(false);
          setnoVerifSiret(false);
          setsiretName(response.data.etablissement.uniteLegale.denominationUniteLegale);
        }
      })
      .catch(function (error) {
        seterrorSiret(true);
        seterrorSiretString(false);
        setsuccessSiret(false);
        setnoVerifSiret(false);
        setsiretName('')
        console.log(error);
      });   
    } else {
      seterrorSiretEmpty(false);
      setnoVerifSiret(false);
      setsuccessSiret(false);
      seterrorSiretString(false);
      seterrorSiret(false);
    }
  }
}
  return (
    <React.Fragment>
      <MediaQuery minWidth={750}>
      <div className="account-home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="mdi mdi-home h1"></i>
        </Link>
      </div>

      <section className="bg-account-pages vh-100">
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5}>
                  <Card className="account-card">
                    <CardBody>
                      <LanguageSelector setlanguage={setlanguage} />
                      <div className="text-center mt-3">
                        <h3 className="font-weight-bold">
                          <Link
                            to="/"
                            style={{ color: "#eb502e"}}
                            className="text-uppercase account-pages-logo"
                          > 
                            <img
                              src="../assets/images/logo/ipocamp.png"
                              style={{          
                                height: 35,
                                width: "110.63px",
                                margin: 0,
                              }}
                              alt="logo"
                            />
                          </Link>
                        </h3>
                      <Text tid="signup.subtitle" />
                      </div>
                      <div className="p-3">
                      <Formik 
                        style={{ backgroundColor: "#FFFFFF"}}
                        onSubmit={handleSubmit}
                      >
                          <Form>
                            <FormGroup style={{ marginBottom: "20px" }}>
                              <Label 
                                htmlFor="firstname"
                                style={{
                                  fontSize: "10px",
                                  marginTop: "10px",
                                  marginLeft: '10px',
                                  marginBottom: "0px",
                                  height: '15px',
                                  color: "#787878",
                                  backgrounColor: '#FFFFFF',
                                  display: 'flex',
                                  position: 'relative',
                                  zIndex:10,
                                  width: '90px'
                                }}
                              >
                                <Text tid="signup.firstnamefield" />*
                              </Label>
                              <Field
                                onChange={(e) => {handleFirstName(e)}}
                                name="lastname"
                                // required
                                type="text"
                                className="form-control"
                                id="lastname"
                                // placeholder={placeholderFirstName()}
                              />
                            <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-10px", marginBottom: errorAllEmptyFirstName ? "-20px" : "0px", color:"red"}}>
                              {errorAllEmptyFirstName === true ? (errorNotificationAllFieldsEmpty()) : null}
                            </div>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "20px" }}>
                              <Label 
                                  htmlFor="firstname"
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "10px",
                                    marginLeft: '10px',
                                    marginBottom: "0px",
                                    height: '15px',
                                    color: "#787878",
                                    backgrounColor: '#FFFFFF',
                                    display: 'flex',
                                    position: 'relative',
                                    zIndex:10,
                                    width: '90px'
                                  }}
                              >
                                <Text tid="signup.lastnamefield" />*
                              </Label>
                              <Field
                                onChange={(e) => {handleLastName(e)}}
                                name="lastname"
                                // required
                                type="text"
                                className="form-control"
                                id="lastname"
                                // placeholder={placeholderLastName()}
                              />
                              <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-10px", marginBottom: errorAllEmptyLastName ? "-20px" : "0px", color:"red"}}>
                                {errorAllEmptyLastName === true ? (errorNotificationAllFieldsEmpty()) : null}
                              </div>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "20px" }}>
                              <Label 
                                  htmlFor="firstname"
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "20px",
                                    marginLeft: '10px',
                                    marginBottom: "0px",
                                    height: '15px',
                                    color: "#787878",
                                    backgrounColor: '#FFFFFF',
                                    display: 'flex',
                                    position: 'relative',
                                    zIndex:10,
                                    width: '90px'
                                  }}
                              >
                                <Text tid="signup.emailfield" />*
                              </Label>
                              <Field
                                onChange={handleEmail}
                                type="email"
                                name="email"
                                // required
                                className="form-control"
                                id="email"
                                // placeholder={placeholderEmail()}
                              />
                              <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-10px", marginBottom: errorAllEmptyEmail? "-20px" : "0px", color:"red"}}>
                                {errorAllEmptyEmail === true ? (errorNotificationAllFieldsEmpty()) : null}
                              </div>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "20px" }}>
                              <Label 
                                  htmlFor="firstname"
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "10px",
                                    marginLeft: '10px',
                                    marginBottom: "0px",
                                    height: '15px',
                                    color: "#787878",
                                    backgrounColor: '#FFFFFF',
                                    display: 'flex',
                                    position: 'relative',
                                    zIndex:10,
                                    width: '90px'
                                  }}
                              >
                                <Text tid="signup.firmfield" />
                              </Label>
                              <Field
                                disabled={siret !== 0 ? true : false}
                                onChange={(e) => handleEntreprise(e)}
                                type="entreprise"
                                name="entreprise"
                                // required
                                className="form-control"
                                id="entreprise"
                                placeholder={entreprise === '' && siretName === '' ? '' : entreprise === '' ? siretName : entreprise}
                              />
                              <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-10px", marginBottom: errorAllEmptyEntreprise ? "-20px" : "0px", color:"red"}}>
                                {errorAllEmptyEntreprise === true ? (errorNotificationAllFieldsEmpty()) : null}
                              </div>
                            </FormGroup>
                            <Row>
                              <MediaQuery minWidth={1025}>
                                <Col style={{ height: '50px', marginBottom: '20px' }}>
                                  <FormGroup style={{ marginBottom: '20px' }}>
                                    <Label 
                                        htmlFor="firstname"
                                        style={{
                                          fontSize: "10px",
                                          marginLeft: '10px',
                                          marginBottom: "10px",
                                          height: '15px',
                                          color: "#787878",
                                          backgrounColor: '#FFFFFF',
                                          display: 'flex',
                                          position: 'relative',
                                          zIndex:10,
                                          width: '90px'
                                        }}
                                    >
                                      <Text tid="signup.siretfield" />
                                    </Label>
                                    <Field
                                      onChange={(e) => {handleSiret(e)}}
                                      name="lastname"
                                      // required
                                      style={{ width: "300px", marginTop: "-10px" }}
                                      type="text"
                                      className="form-control"
                                      id="lastname"
                                      // placeholder={placeholderSiret()}
                                    />
                                  </FormGroup>
                                 </Col>
                               </ MediaQuery>
                               <MediaQuery maxWidth={1025}>
                                <Col style={{ height: '50px', marginBottom: '20px' }}>
                                  <FormGroup style={{ marginBottom: '20px' }}>
                                    <Label 
                                        htmlFor="firstname"
                                        style={{
                                          fontSize: "10px",
                                          marginLeft: '10px',
                                          marginBottom: "10px",
                                          height: '15px',
                                          color: "#787878",
                                          backgrounColor: '#FFFFFF',
                                          display: 'flex',
                                          position: 'relative',
                                          zIndex:10,
                                          width: '90px'
                                        }}
                                    >
                                      <Text tid="signup.siretfield" />
                                    </Label>
                                    <MediaQuery minWidth={769}>
                                      <Field
                                        // disabled={entreprise !== '' ? true : false}
                                        onChange={(e) => {handleSiret(e)}}
                                        name="lastname"
                                        // required
                                        style={{ width: "75%", marginTop: "-10px" }}
                                        type="text"
                                        className="form-control"
                                        id="lastname"
                                        // placeholder={placeholderSiret()}
                                      />
                                    </MediaQuery>
                                    <MediaQuery maxWidth={769}>
                                      <Field
                                        // disabled={entreprise !== '' ? true : false}
                                        onChange={(e) => {handleSiret(e)}}
                                        name="lastname"
                                        // required
                                        style={{ width: "85%", marginTop: "-10px" }}
                                        type="text"
                                        className="form-control"
                                        id="lastname"
                                        // placeholder={placeholderSiret()}
                                      />
                                    </MediaQuery>
                                  </FormGroup>
                                 </Col>
                               </ MediaQuery>
                              <Col style={{ top: "25px", marginLeft: "-90px", height: "20px", minWidth: "10%", maxWidth: "10%"}}>
                                <Button
                                  disabled={entreprise !== '' ? true : false}
                                  style={{
                                    width: "100px",
                                    marginTop: "-10px",
                                    border: "none",
                                    borderRadius: "20px",
                                    color: "black",
                                    marginLeft: "-15px"
                                  }}
                                  // onClick={userRegister}
                                  onClick={entreprise === '' ? checkResetSiret : null}
                                  // onClick={handleVariable}
                                  color="none"
                                  className="btn text-chart"
                                >
                                  <Text tid="signup.validationsiret" />
                                </Button>
                             </Col>
                            <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-5px", marginBottom: "0px", color: successSiret? ("green") : ("red")}}>
                              {errorEmptySiret === true ? (errorNotificationAllFieldsEmpty()) : null}
                              {successSiret === true ? (successNotificationSiret()) : null}
                              {errorSiret === true ? (errorNotificationSiret()) : null}
                              {errorSiretString === true ? (errorNotificationSiretString(language)) : null}
                              {noVerifSiret ? (errorNotificationNoVerifSiret(language)) : null}
                            </div>
                            </Row>
                              <MediaQuery minWidth={1025}>
                                <FormGroup style={{ marginBottom: "20px"}}>
                                  <Label 
                                      htmlFor="firstname"
                                      style={{
                                        fontSize: "10px",
                                        marginLeft: '10px',
                                        marginBottom: "0px",
                                        height: '15px',
                                        color: "#787878",
                                        backgrounColor: '#FFFFFF',
                                        display: 'flex',
                                        position: 'relative',
                                        zIndex:10,
                                        width: '90px'
                                      }}
                                  >
                                    <Text tid="signup.passfield" />*
                                  </Label>
                                  <Row>
                                    <Col>
                                  <Field
                                    onChange={handlePassword}
                                    type={showPasswordTwo ? "text" : "password"}
                                    name="password"
                                    // required
                                    style={{ width: '385px'}}
                                    className="form-control"
                                    id="userpassword"
                                    // placeholder={placeholderPassword()}
                                  />
                                  </Col>
                                  <Col>
                                  <img
                                  onClick={()=> {
                                    setshowPasswordTwo(!showPasswordTwo)
                                  }}
                                  style={{
                                    position: "relative",
                                    zIndex:10,
                                    width: "15px",
                                    height: "15px",
                                    marginLeft: "350px",
                                    marginTop: "-100px",
                                    cursor: "pointer"
                                  }}
                                  src="assets/images/icons/visibility.png"
                                  alt="eye"
                                />
                                </Col>
                                </Row>
                                  <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-35px", marginBottom: errorEmptyPassword || badPasswordType ? "50px" : "0px", color:"red"}}>
                                    {errorEmptyPassword === true ? (errorNotificationAllFieldsEmpty()) : null}
                                    {badPasswordType === true && errorEmptyPassword === false ? (errorNotificationPasswordType()) : null}
                                  </div>
                                </FormGroup>
                              </MediaQuery>
                              <MediaQuery maxWidth={1025}>
                                <FormGroup style={{ marginBottom: "0px"}}>
                                  <Label 
                                      htmlFor="firstname"
                                      style={{
                                        fontSize: "10px",
                                        marginLeft: '10px',
                                        marginBottom: "0px",
                                        height: '15px',
                                        color: "#787878",
                                        backgrounColor: '#FFFFFF',
                                        display: 'flex',
                                        position: 'relative',
                                        zIndex:10,
                                        width: '90px'
                                      }}
                                  >
                                    <Text tid="signup.passfield" />*
                                  </Label>
                                  <Row>
                                    <Col>
                                  <Field
                                    onChange={handlePassword}
                                    type={showPasswordTwo ? "text" : "password"}
                                    name="password"
                                    // required
                                    style={{ width: '100%'}}
                                    className="form-control"
                                    id="userpassword"
                                    // placeholder={placeholderPassword()}
                                  />
                                  </Col>
                                  <MediaQuery minWidth={769}>
                                    <Col>
                                    <img
                                    onClick={()=> {
                                      setshowPasswordTwo(!showPasswordTwo)
                                    }}
                                    style={{
                                      position: "relative",
                                      zIndex:10,
                                      width: "15px",
                                      height: "15px",
                                      marginLeft: "270px",
                                      marginTop: "-100px",
                                      cursor: "pointer"
                                    }}
                                    src="assets/images/icons/visibility.png"
                                    alt="eye"
                                  />
                                  </Col>
                                </MediaQuery>
                                <MediaQuery maxWidth={769}>
                                <Col style={{ minWidth: "100%", maxWidth: "100%"}}>
                                      <img
                                        onClick={()=> {
                                          setshowPasswordTwo(!showPasswordTwo)
                                        }}
                                        style={{
                                          position: "relative",
                                          zIndex:10,
                                          width: "15px",
                                          height: "15px",
                                          marginLeft: "590px",
                                          marginTop: "-100px",
                                          cursor: "pointer"
                                        }}
                                        src="assets/images/icons/visibility.png"
                                        alt="eye"
                                      />
                                    </Col>
                                </MediaQuery>  
                                </Row>
                                  <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-35px", marginBottom: errorEmptyPassword || badPasswordType ? "50px" : "0px", color:"red"}}>
                                    {errorEmptyPassword === true ? (errorNotificationAllFieldsEmpty()) : null}
                                    {badPasswordType === true && errorEmptyPassword === false ? (errorNotificationPasswordType()) : null}
                                  </div>
                                </FormGroup>
                              </MediaQuery>
                              <MediaQuery minWidth={1025}>
                                <FormGroup>
                                  <Label 
                                    htmlFor="firstname"
                                    style={{
                                      fontSize: "10px",
                                      marginTop: '-50px',
                                      marginLeft: '10px',
                                      marginBottom: "0px",
                                      height: '15px',
                                      color: "#787878",
                                      backgrounColor: '#FFFFFF',
                                      display: 'flex',
                                      position: 'relative',
                                      zIndex:10,
                                      width: '150px'
                                    }}
                                  >
                                    <Text tid="signup.confirm" />*
                                  </Label>
                                  <Row>
                                    <Col>
                                      <Field
                                        onChange={handlePasswordConfirm}
                                        type={showPasswordOne ? "text" : "password"}
                                        name="password"
                                        style={{ width: '100%'}}
                                        // required
                                        className="form-control"
                                        id="userpassword"
                                        // placeholder={placeholderPasswordConfirm()}
                                      />
                                    </Col>
                                    <MediaQuery minWidth={1025}>
                                      <Col>
                                        <img
                                          onClick={()=> {
                                            setshowPasswordOne(!showPasswordOne)
                                          }}
                                          style={{
                                            position: "relative",
                                            zIndex:10,
                                            width: "15px",
                                            height: "15px",
                                            marginLeft: "350px",
                                            marginTop: "-100px",
                                            cursor: "pointer"
                                          }}
                                          src="assets/images/icons/visibility.png"
                                          alt="eye"
                                        />
                                      </Col>
                                    </MediaQuery>
                                  </Row>
                                  <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-35px", color:"red"}}>
                                    {errorEmptyPassword === true ? (errorNotificationAllFieldsEmpty()) : null}
                                    {errorpasswordNotSame === true ? (errorNotificationpassword()) : null}
                                  </div>
                                </FormGroup>
                              </MediaQuery>
                              <MediaQuery maxWidth={1025}>
                                <FormGroup className="mb-5">
                                  <Label 
                                    htmlFor="firstname"
                                    style={{
                                      fontSize: "10px",
                                      marginTop: '10px',
                                      marginLeft: '10px',
                                      marginBottom: "0px",
                                      height: '15px',
                                      color: "#787878",
                                      backgrounColor: '#FFFFFF',
                                      display: 'flex',
                                      position: 'relative',
                                      zIndex:10,
                                      width: '150px'
                                    }}
                                  >
                                    <Text tid="signup.confirm" />*
                                  </Label>
                                  <Row>
                                    <Col>
                                      <Field
                                        onChange={handlePasswordConfirm}
                                        type={showPasswordOne ? "text" : "password"}
                                        name="password"
                                        style={{width: "100%"}}
                                        // required
                                        className="form-control"
                                        id="userpassword"
                                        // placeholder={placeholderPasswordConfirm()}
                                      />
                                    </Col>
                                    <MediaQuery minWidth={769}>
                                      <Col>
                                        <img
                                          onClick={()=> {
                                            setshowPasswordOne(!showPasswordOne)
                                          }}
                                          style={{
                                            position: "relative",
                                            zIndex:10,
                                            width: "15px",
                                            height: "15px",
                                            marginLeft: "270px",
                                            marginTop: "-100px",
                                            cursor: "pointer"
                                          }}
                                          src="assets/images/icons/visibility.png"
                                          alt="eye"
                                        />
                                      </Col>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={769}>
                                    <Col style={{ minWidth: "100%", maxWidth: "100%"}}>
                                      <img
                                        onClick={()=> {
                                          setshowPasswordOne(!showPasswordOne)
                                        }}
                                        style={{
                                          position: "relative",
                                          zIndex:10,
                                          width: "15px",
                                          height: "15px",
                                          marginLeft: "590px",
                                          marginTop: "-100px",
                                          cursor: "pointer"
                                        }}
                                        src="assets/images/icons/visibility.png"
                                        alt="eye"
                                      />
                                    </Col>
                                  </MediaQuery>
                                </Row>
                                  <div style={{ fontSize: "10px", marginLeft: "10px", marginTop:"-35px", color:"red"}}>
                                    {errorEmptyPassword === true ? (errorNotificationAllFieldsEmpty()) : null}
                                    {errorpasswordNotSame === true ? (errorNotificationpassword()) : null}
                                  </div>
                                </FormGroup>
                              </MediaQuery>

                            {/* <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="customControlInline"
                              />{" "}
                              <Label
                                className="custom-control-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </Label>
                            </div> */}

                            { error === true ? (errorNotification()) : null}
                            <div className="d-grid mt-3">
                              <Button
                                // onClick={userRegister}
                                onClick={handleSubmit}
                                // onClick={handleVariable}
                                color="none"
                                className="btn bg-color-chart text-chart"
                              >
                                <Text tid="signup.validationbutton" />
                              </Button>
                            </div>

                            <div className="mt-4 mb-0 text-center">
                              <p className="mb-0">
                              <Text tid="signup.account" />{" "}
                                <Link to="/login" style={{ color: "#EB502E"}}>
                                <Text tid="signup.account-redirect" />
                                </Link>
                              </p>
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
      </ MediaQuery>
      <MediaQuery maxWidth={750}>
        <SignupReponsive/>
      </MediaQuery>
    </React.Fragment>
  );
}

export default Signup;
