import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  // NavItem,
  // NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";
import { useHistory } from 'react-router-dom';
// import ScrollspyNav from "./scrollSpy";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import { backLink} from '../../constants/defaultValues';
//stickey header
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";
// import { Link } from "react-router-dom";

import MediaQuery from 'react-responsive';

import { Text } from '../../containers/Language';

import LanguageSelectorNavbar from '../LanguageSelectorNavbar';

// import { backLink } from '../../constants/defaultValues';

import { delete_cookie } from 'sfcookies';

import { getCurrentUser } from '../../helpers/Utils';

// import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 35,
    width: "110.63px",
    margin: 0,
    marginLeft: "10%",
  },
  logoResponsive: {
      height: 40,
      width: "100%",
      margin: 0,
      marginLeft: "10%",
      marginRight: "10px"
  },
    NavBarStyle: {
      backgroundColor: "#fdf8f4",
      color: "#0c1d37",
      width: "100%"
    },
    boxContent: {
      backgroundColor: "#fdf8f4",
      color: "#0c1d37",
      width: "100%"
    },
    BoxTitle: {
      width: "50%",
      justifyContent: "center"
    },
    toggleStyle: {
      color: "black"
    },
    ButtonLogin: {
      color: "#0c1d37",
      backgroundColor: "#fdf8f4",
      border: "2px solid",
      width: "200px"
    }
}));

function NavbarPage(props) {
  
  // eslint-disable-next-line no-unused-vars
  // const [language, setlanguage] = useState('');
  // const [permissionLevel, setpermissionLevel] = useState('');
  // const [firstname, setfirstname] = useState('');
  // const [lastname, setlastname] = useState('');
  // const [email, setemail] = useState('');
  // const [apiKey, setapiKey] = useState('');
  // const [company, setcompany] = useState('');
  // const [siret, setsiret] = useState('');
  // const [userId, setuserId] = useState("");
  // const [username, setusername] = useState("");
  // const [email, setemail] = useState("");
  const [login, setlogin] = useState(false);
  let history = useHistory();
  
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  // let isOpenMenu = false
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  
  const open = Boolean(anchorEl);
  
  useEffect(() => {
    // console.log('login', props.language);
    if (login === false) {
      if(getCurrentUser() === null) {
        setlogin(false);
      } else {
        setlogin(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, props])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const consoleLog = () => {
  //   console.log("props", props);
  // }
  // consoleLog();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggle = () => {
    setIsOpenMenu(!isOpenMenu)
  };
  const redirectProfil = () => {
    history.push('/profil')
  }
  const redirectLogin = () => {
    history.push('/login')
  }
  const redirectSignUp = () => {
    history.push('/sign-up')
  }
  const redirectSwagger = () => {
    history.push('/documentation')
  }
  const redirectDashboard = () => {
    history.push('/dashboard')
  }
  /* const handlelocal = () => {
    console.log('username', username);
    console.log('userId', userId);
    console.log('email', email);
    console.log('login', login);
    console.log("backlink", backLink);
  } */
  const clearLocalStorage = () => {
    console.log("props", props)
    localStorage.removeItem('accessToken');
    localStorage.removeItem('client_id');
    localStorage.removeItem('permission_level');
    localStorage.removeItem('current_user');
    localStorage.removeItem('confirmToken');
    localStorage.removeItem('tokenExp');
    delete_cookie('refreshToken')
    localStorage.setItem('login', false);
    setlogin(false);
    history.push('/login');
    setAnchorEl(null);
  }
  useEffect(() => {
    let log = localStorage.getItem('login')
    // console.log('login', props.login);
    if (log === false) {
      if(getCurrentUser() !== ""|| getCurrentUser() !== null || getCurrentUser() !== undefined) {
        setlogin(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /*const handleLog = () => {
    console.log("navItems", props.navItems);
    console.log("navClass", props.navClass);
  }*/
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    const displayName = () => {
      if(props.first_name === undefined) {
        return `${getCurrentUser().first_name} ${getCurrentUser().last_name}`
      } else {
        return `${props.first_name} ${props.last_name}`
      }
    }
    return (
      <React.Fragment>
        <StickyHeader
          header={
            <Navbar
              expand="lg"
              fixed="top"
              style={{ backgroundColor: "#fdf8f4", color: "#0c1d37", width: "100%" }}
              className={
                "navbar-custom sticky sticky-dark " + props.navClass
              }
            >
              <Container className={classes.boxContent}>
                <LanguageSelectorNavbar setlanguage={props.setlanguage} />
                <MediaQuery minWidth={980}>
                  <NavbarBrand className="logo text-uppercase" style={{ cursor: "pointer"}} onClick={() => { history.push('/') }}>
                    <img src="../assets/images/logo/ipocamp.png" className={classes.logo} alt="logo" />
                  </NavbarBrand>
                </ MediaQuery>
                <MediaQuery maxWidth={980}>
                  <NavbarBrand className="logo text-uppercase" style={{ cursor: "pointer"}} onClick={() => { history.push('/') }}>
                    <img src="../assets/images/logo/ipocamp.png" className={classes.logoResponsive} alt="logo" />
                  </NavbarBrand>
                </ MediaQuery>
                <NavbarToggler className={classes.toggleStyle} onClick={toggle}>
                  <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse
                  id="navbarCollapse"
                  isOpen={isOpenMenu}
                  navbar
                >
                    <div className="nav-button ms-auto">
                      {
                        login === false ? (
                          <div>
                      <MediaQuery minWidth={975}>
                      <Nav navbar className="navbar-end">
                        <Button
                            style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "none", width: "250px", margin: "auto", }}
                            type="button"
                            className="btn navbar-btn btn-rounded waves-effect waves-light"
                            onClick={redirectSwagger}
                            // onClick={handleLog}
                          >
                            Documentation API
                        </Button>
                        <Button
                            style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "none", width: "200px" }}
                            type="button"
                            className="btn navbar-btn btn-rounded waves-effect waves-light"
                            onClick={redirectSignUp}
                            // onClick={handleLog}
                          >
                            <Text tid="navbar.signup" />
                          </Button>
                          <Button
                            style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "2px solid", width: "200px" }}
                            type="button"
                            className={classes.ButtonLogin + "btn navbar-btn btn-rounded waves-effect waves-light"}
                            onClick={redirectLogin}
                            // onClick={handleLog}
                          >
                            <Text tid="navbar.login" />
                          </Button>
                      </Nav>
                      </MediaQuery>
                      <MediaQuery maxWidth={975}>
                        <div style={{ display: 'flex', justifyContent: 'left'}}>
                          <ul>
                          <p style={{ marginBottom: "15px", marginTop: "5px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectSwagger}>API</p>
                          <p style={{ marginBottom: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectSignUp}><Text tid="navbar.signup" /></p>
                          <p style={{ marginBottom: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectLogin}><Text tid="navbar.login" /></p>
                          </ul>
                        </div>
                      </MediaQuery>
                      </div>
                      ) : (
                        <Nav navbar className="navbar-end">
                        <MediaQuery minWidth={975}>
                          <Button
                              style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "none", width: "250px", margin: "auto", }}
                              type="button"
                              className="btn navbar-btn btn-rounded waves-effect waves-light"
                              onClick={redirectSwagger}
                              // onClick={handleLog}
                            >
                              Documentation API
                          </Button>
                          <Button
                            style={{ color: "#0c1d37", backgroundColor: "#fdf8f4", border: "2px solid", width: "200px" }}
                            className={classes.ButtonLogin + "btn navbar-btn btn-rounded waves-effect waves-light"}
                            id="basic-button"
                            aria-controls="basic-menu"
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                          >
                            {displayName()}
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={redirectDashboard}>Dashboard</MenuItem>
                            <MenuItem onClick={redirectProfil}><Text tid="navbar.profil" /></MenuItem>
                            <MenuItem onClick={clearLocalStorage}><Text tid="navbar.logout" /></MenuItem>
                          </Menu>
                          </ MediaQuery>
                          <MediaQuery maxWidth={975}>
                            <div style={{ display: 'flex', justifyContent: 'left'}}>
                              <ul>
                              <p style={{ marginBottom: "15px", marginTop: "5px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectSwagger}>API</p>
                              <p style={{ marginBottom: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectDashboard}>Dashboard</p>
                              <p style={{ marginBottom: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={redirectProfil}><Text tid="navbar.profil" /></p>
                              <p style={{ marginBottom: "15px", cursor: 'pointer', marginLeft: '-32px', color: '#16151599' }} onClick={clearLocalStorage}><Text tid="navbar.logout" /></p>
                              </ul>
                            </div>
                          </MediaQuery>
                        </Nav>
                      )
                    }
                    </div>
                </Collapse>
              </Container>
            </Navbar>
          }
          stickyOffset={-100}
        ></StickyHeader>
      </React.Fragment>
    );
  }

export default NavbarPage;
