import React, { useState, useEffect } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Service from "../../components/Service/service";
// import AboutUs from "../../components/AboutUs/AboutUs";
// import WebsiteDesc from "../../components/WebsiteDesc/WebsiteDesc";
import Pricing from "../../components/Pricing/pricing";
// import Team from "../../components/Team/Team";
import Process from "../../components/Process/Process";
// import Testimonials from "../../components/Testimonials/Testimonials";
// import GetStart from "../../components/GetStart/GetStart";
// import Blog from "../../components/Blog/Blog";
// import Contact from "../../components/Contact/Contact";
// import Social from "../../components/Social/Social";
import Footer from "../../components/Footer/footer";

import Details from "../../components/Details/Details";

import DetailsResp from "../../components/Details/DetailsResp";

import { Text } from '../../containers/Language';

import { getCurrentUser } from "../../helpers/Utils";

import MediaQuery from 'react-responsive';

import Modal from "../../components/containers/ui/Modal";

const Index1 = (props) => {
  const [accessToken, setaccessToken] = useState('');
  // const [priceId, setpriceId] = useState();
  const [defaultValue, setDefaultValue] = useState();
  const [login, setlogin] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [language, setlanguage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [navItems, setNavItems] = useState([
    { id: 1, idnm: "home", navheading: <Text tid="navbar.home" /> },
    { id: 2, idnm: "services", navheading: <Text tid="navbar.service" /> },
    // { id: 4, idnm: "pricing", navheading: <Text tid="navbar.pricing" /> },
    { id: 5, idnm: "process", navheading: <Text tid="navbar.process" /> },
  ],)
  // eslint-disable-next-line no-unused-vars
  const [navItem, setNavItem] = useState([
    { id: 1, idnm: "home", navheading: <Text tid="navbar.home" /> },
    { id: 2, idnm: "services", navheading: <Text tid="navbar.service" /> },
    { id: 5, idnm: "process", navheading: <Text tid="navbar.process" /> },
  ],)
  // eslint-disable-next-line no-unused-vars
  const [navClass, setNavClass] = useState("");

  const [newDeposit, setnewDeposit] = useState();

  const [pricing, setpricing] = useState();
  
  const [modalAlert, setmodalAlert] = useState(false);

  const [isLogin, setisLogin] = useState(JSON.parse(localStorage.getItem('login')));

  const [permission_level, setpermission_level] = useState(getCurrentUser() !== null ? getCurrentUser().permission_level : null);

  // useEffect(() => {
  //   setaccessToken(window.localStorage.getItem('accessToken'));
  //   setclientId(window.localStorage.getItem('client_id'));
  //   setpermissionLevel(window.localStorage.getItem('permission_level'));
  // }, [accessToken, clientId, permissionLevel])
  useEffect(() => {
    // console.log('props', props);
    // console.log('props index', typeof getCurrentUser());
    let log;
    log = JSON.parse(window.localStorage.getItem('login'))
    setaccessToken(window.localStorage.getItem('accessToken'));
    if (log === false) {
      if(getCurrentUser() === "" || getCurrentUser() === null) {
        // console.log('accessToken', accessToken);
        // console.log('clientId', clientId);
        // console.log('permissionLevel', permissionLevel);
      } else {
        // console.log("hello it's me");
        setlogin(log);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          accessToken={accessToken}
          navItems={navItems}
          navClass={navItems}
          // userProfile={userProfile}
          setlanguage={setlanguage}
          setlogin={setlogin}
          login={login}
        />

        <div style={{ backgroundColor:"#fdf8f4"}}>

        {/* section */}
        <Section />
        {modalAlert ? (
          <Modal
            created_date={props.created_date}
            pricing={pricing}
            modalAlert={modalAlert}
            setmodalAlert={setmodalAlert} 
            stripeRequest={props.stripeRequest}
            newDeposit={newDeposit}
          />
          ) : null
        }
        {/* services */}
        <Service />

          {/* <div style={{ borderTop: "1px solid #d3d3d3", marginBottom: "3rem", marginTop: "3rem", backgroundColor:"#fdf8f4"}} /> */}

        {/* pricing */}
          {/* <Pricing
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            priceId={props.priceId}
            pricing={pricing}
            setpricing={setpricing}
            setpriceId={props.setpriceId}
            stripeRequest={props.stripeRequest}
            setgetPay={props.setgetPay}
            login={login}
            setnewDeposit={setnewDeposit}
            permission={permission_level !== null ? getCurrentUser().permission_level : null}
            setmodalAlert={setmodalAlert}
          /> */}

        {/* <div className="" style={{ borderTop: "1px solid #d3d3d3", marginTop: "3rem", backgroundColor:"#fdf8f4"}} />
        <MediaQuery minWidth={1025}>
          <Details box={1} />
          <Details box={2} />
        </MediaQuery>
        <MediaQuery maxWidth={1025}>
          <DetailsResp box={1} />
          <DetailsResp box={2} />
        </MediaQuery> */}
        <div className="" style={{ borderTop: "1px solid #d3d3d3", marginTop: "3rem", backgroundColor:"#fdf8f4"}} />
        <Process />

        <div className="" style={{ borderTop: "1px solid #d3d3d3", marginTop: "3rem", backgroundColor:"#fdf8f4"}} />
        <Footer />
        </div>
      </React.Fragment>
    );
  }

export default Index1;
