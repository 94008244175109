export const successNotificationSiret = (lang) => {
  let locallang = localStorage.getItem('rcml-lang');
  if(lang) {
    if(lang === "en") {
    return(
        "SIRET number found !"
    )
    } else {
      return (
          "Numéro de SIRET valide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "SIRET number found !"
      )
      } else {
        return (
          "Numéro de SIRET valide"
        )
      }
  }
}
export const errorNotificationNoVerifSiret = (lang) => {
  let locallang = localStorage.getItem('rcml-lang');
  if(lang) {
    if(lang === "en") {
    return(
        "Please check your SIRET number !"
    )
    } else {
      return (
          "Veuillez vérifier votre numéro de Siret"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "Please check your SIRET number !"
      )
      } else {
        return (
          "Veuillez vérifier votre numéro de Siret"
        )
      }
  }
}
export const errorNotificationSiret = (lang) => {
  let locallang = localStorage.getItem('rcml-lang');
  if(lang) {
    if(lang === "en") {
    return(
        "SIRET number not found !"
    )
    } else {
      return (
          "Numéro de SIRET invalide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "SIRET number not found !"
      )
      } else {
        return (
          "Numéro de SIRET invalide"
        )
      }
  }
}

export const errorNotificationNotSame = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "the new password and the password confirm have to be the same"
      )
      } else {
        return (
            "le nouveau mot de passe et le mot de passe de confirmation doivent-être identique"
        )
      }
    } else {
      if(locallang === "en") {
        return(
            "the new password and the password confirm have to be the same"
        )
        } else {
          return (
              "le nouveau mot de passe et le mot de passe de confirmation doivent-être identique"
          )
        }
    }
  }
export const successNotificationPassword = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "Your password has been modified"
      )
      } else {
        return (
            "Votre mot de passe a bien été modifié"
        )
      }
    } else {
      if(locallang === "en") {
        return(
          "Your password has been modified"
        )
        } else {
          return (
            "Votre mot de passe a bien été modifié"
          )
        }
    }
  }
export const errorNotificationName = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "this field is empty"
      )
      } else {
        return (
            "ce champ est vide"
        )
      }
    } else {
      if(locallang === "en") {
        return(
            "this field is empty"
        )
        } else {
          return (
              "ce champ est vide"
          )
        }
    }
  }
export const errorNotificationEntrepriseEmpty = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "this field is empty"
      )
      } else {
        return (
            "ce champ est vide"
        )
      }
    } else {
      if(locallang === "en") {
        return(
            "this field is empty"
        )
        } else {
          return (
              "ce champ est vide"
          )
        }
    }
  }
export const errorNotificationSiretEmpty = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "this field is empty"
      )
      } else {
        return (
            "ce champ est vide"
        )
      }
    } else {
      if(locallang === "en") {
        return(
            "this field is empty"
        )
        } else {
          return (
              "ce champ est vide"
          )
        }
    }
  }
export const successNotificationFirstName = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "Your first name have been modified"
      )
      } else {
        return (
            "Votre prénom a été modifié"
        )
      }
    } else {
      if(locallang === "en") {
        return(
          "Your first name have been modified"
        )
        } else {
          return (
            "Votre prénom a été modifié"
          )
        }
    }
  }
export  const successNotificationLastName = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "Your last name have been modified"
      )
      } else {
        return (
            "Votre nom de famille a été modifié"
        )
      }
    } else {
      if(locallang === "en") {
        return(
          "Your last name have been modified"
        )
        } else {
          return (
            "Votre nom de famille a été modifié"
          )
        }
    }
  }
export const errorNotificationSiretString = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "Your SIRET number type is incorrect"
      )
      } else {
        return (
            "Le format de votre numéro de SIRET est invalide"
        )
      }
    } else {
      if(locallang === "en") {
        return(
          "Your SIRET number type is incorrect"
        )
        } else {
          return (
            "Le format de votre numéro de SIRET est invalide"
          )
        }
    }
  }
  export const errorNotificationCurrentPassword = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "the current password is incorrect"
      )
      } else {
        return (
            "le mot de passe actuel est incorrect"
        )
      }
    } else {
      if(locallang === "en") {
        return(
          "the current password is incorrect"
        )
        } else {
          return (
            "le mot de passe actuel est incorrect"
          )
        }
    }
  }
  export const errorNotificationPasswordType = (lang) => {
  let locallang = localStorage.getItem('rcml-lang');
  if(lang) {
    if(lang === "en") {
    return(
        "The password must contain at least one uppercase and 7 characters"
    )
    } else {
      return (
          "le mot de passe doit contenir au moins une majuscule et 7 caractères"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "The password must contain at least one uppercase and 7 characters"
      )
      } else {
        return (
          "le mot de passe doit contenir au moins une majuscule et 7 caractères"
        )
      }
  }
}
  export const errorNotificationAllFieldsEmpty = (lang) => {
    let locallang = localStorage.getItem('rcml-lang');
    if(lang) {
      if(lang === "en") {
      return(
          "this field is empty"
      )
      } else {
        return (
            "ce champ est vide"
        )
      }
    } else {
      if(locallang === "en") {
        return(
            "this field is empty"
        )
        } else {
          return (
              "ce champ est vide"
          )
        }
    }
  }