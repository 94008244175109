import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ReactSlider from "react-slider";

import { Text } from '../../containers/Language';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const CustomizedSlider = (props, defaultValue, setDefaultValue, ) => {
  const classes = useStyles();
  const handleItems = (e) => {
    props.setchange(props.change + 1);
    props.setDefaultValue(e);
  }
  return (
    <div className={classes.root}>
      <div className={classes.margin} />
      <Typography gutterBottom><Text tid="pricing.slider" /></Typography>
      <ReactSlider
        onChange={handleItems}
        // onAfterChange={handleItems}
        // onBeforeChange={handleItems}
        onSliderClick={handleItems}
        max={10000}
        min={1000}
        step={1000}
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        renderThumb={(props, state) => 
        <div {...props}>
          <p style={{ width : "180px", marginTop: "10px", fontWeight: '500', pointerEvents: 'none'}}>
            {state.valueNow} <Text tid="pricing.period" />
          </p>
        </div>}
      />
    </div>
  );
}
export default CustomizedSlider;