import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  // Input,
  Button,
} from "reactstrap";

import { Text } from '../../containers/Language';
// import { backLink } from '../../constants/defaultValues';

import MediaQuery from 'react-responsive';

import LanguageSelector from '../../components/LanguageSelector';

import axios from 'axios';

import { backLink } from '../../constants/defaultValues';

import { useHistory } from 'react-router-dom';

import { Form, Field, Formik } from 'formik';

import {
  errorNotificationSiretString,
  errorNotificationNoVerifSiret
} from '../../helpers/errorNotification';
// import { AvForm, AvField } from "availity-reactstrap-validation";

 const Signup = () => {

  let history = useHistory();

  
  const [language, setlanguage] = useState('');
  const [lastName, setlastName] = useState('');
  const [firstName, setfirstName] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [passwordConfirm, setpasswordConfirm] = useState('');
  const [entreprise, setentreprise] = useState('');
  const [siretName, setsiretName] = useState('');
  const [siret, setSiret] = useState(0);
  const [error, seterror] = useState(false);
  const [errorpasswordNotSame, seterrorpasswordNotSame] = useState(false);

  const [successPassword, setsuccessPassword] = useState(false);
  const [badPasswordType, setbadPasswordType] = useState(false);

  const [successSiret, setsuccessSiret] = useState(false);
  const [errorSiret, seterrorSiret] = useState(false);

  const [errorSiretEmpty, seterrorSiretEmpty] = useState(false);
  const [errorSiretString, seterrorSiretString] = useState(false);

  const [noVerifSiret, setnoVerifSiret] = useState(false);

  const [errorAllEmptyLastName, seterrorEmptyLastName] = useState(false);
  const [errorAllEmptyFirstName, seterrorEmptyFirstName] = useState(false);
  const [errorAllEmptyEmail, seterrorEmptyEmail] = useState(false);
  const [errorAllEmptyEntreprise, seterrorEmptyEntreprise] = useState(false);
  const [errorEmptyPassword, seterrorEmptyPassword] = useState(false);
  const [errorEmptySiret, seterrorEmptySiret] = useState(false);

  const [showPasswordOne, setshowPasswordOne] = useState(false);
  const [showPasswordTwo, setshowPasswordTwo] = useState(false);

  // useEffect(() => {
  //   setlanguage(localStorage.getItem('rcml-lang'))
  //   console.log('dictionnary', dictionnary);
  // }, [language])

  const handleSubmit = () => {
    const strongRegex = new RegExp("^(?=.*[A-Z])(?=.{7,})")
    // console.log('siret', typeof siret);
    if( lastName === '') {
      seterrorEmptyLastName(true);
    } else {
      seterrorEmptyLastName(false);
    }
    if (firstName === '') {
      seterrorEmptyFirstName(true)
    } else {
      seterrorEmptyFirstName(false)
    }
    if(email === '') {
      seterrorEmptyEmail(true);
    } else {
      seterrorEmptyEmail(false);
    }
    if (password === '') {

      seterrorEmptyPassword(true);
    } else {
      seterrorEmptyPassword(false);
    }
    if (siret === 0 && entreprise === '') {
      setnoVerifSiret(true);
    } else {
      if(siret.length !== 14) {
        setnoVerifSiret(true);
      }
      if(entreprise !== '') {
        setnoVerifSiret(false);
      }
    }
    if (entreprise === '' && siretName === '') {
      seterrorEmptyEntreprise(true);
    } else {
      seterrorEmptyEntreprise(false);
    }
    if (strongRegex.test(password)){  
      setsuccessPassword(true);
      setbadPasswordType(false);
    } else {  
      setsuccessPassword(false);
      setbadPasswordType(true);
    }
    if (password !== passwordConfirm) {
      seterrorpasswordNotSame(true);
    }
    if(lastName !== '' && firstName !== '' && email !== '' && successPassword && !errorpasswordNotSame && (entreprise !== '' || siretName !== '') && (siret === 0 || siret !== 0)) {
      if(successSiret) {
        if(siret === 0) {
          userRegister();
          setnoVerifSiret(false);
        } else {
          userRegister();
          // console.log('good !')
          setnoVerifSiret(false);
        }
      } else {
        userRegister();
        setnoVerifSiret(false);
      }
    }
  }
  const userRegister = () => {
    // console.log("lastname", lastName);
    // console.log("email", email);
    // console.log("password", password);
    console.log("siret", siret);
    let siretNumber;
    var config = {
      method: 'post',
      url: `${backLink}/clients`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : {
        //"username": username,
        "last_name": lastName,
        "first_name": firstName,
        "email": email,
        "company": siretName !== '' ? siretName : entreprise,
        "siret": siret,
        "password": password
      }
    };
    
    axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // console.log("response.data.status", response.data.status);
      if(response.data.post === "ok") {
        window.localStorage.setItem('confirmToken', response.data.confirmToken);
        history.push('/confirm');
      }
    })
    .catch(function (error) {
      console.log(error);
      seterror(true);
    });
  }
  /* const handleVariable = () => {
    console.log('email', email);
    console.log('password', password);
    console.log('username', username);
  } */  
const handleLastName = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setlastName(e.target.value);
  }
}
const handleFirstName = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setfirstName(e.target.value);
  }
}
const handleSiret = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    if(e.target.value.match("[0-9]")==null) {
      // console.log('nonon')
      seterrorSiretEmpty(false);
      seterrorSiretString(true);
      seterrorEmptySiret(false);
      setsuccessSiret(false);
      seterrorSiret(false);
      setnoVerifSiret(false);
    } else {
      seterrorSiretEmpty(false);
      setsuccessSiret(false);
      seterrorSiretString(false);
      // console.log('okok')
      setSiret(e.target.value.replace(/\s/g, ''));
    }
  } else {
    // console.log('nonon')
    seterrorSiretEmpty(false);
    seterrorSiretString(true);
  }
}
const handleEntreprise = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setentreprise(e.target.value);
  }
} 
const handleEmail = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setemail(e.target.value);
  }
}
const handlePassword = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setpassword(e.target.value);
  }
}
const handlePasswordConfirm = (e) => {
  if(e.target.value.match("[<>]=?|=")==null) {
    setpasswordConfirm(e.target.value);
  }
}
const errorNotification = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
      <div
        className="alert text-center"
        role="alert"
        style={{ backgroundColor: '#F84F31', color: "#FFFFFF" }}
      >
        Your email address  exist in our database, please retry with available email<br />Or Log in you <a href="/login">here</a>
      </div>
    )
    } else {
      return (
        <div
          className="alert text-center"
          role="alert"
          style={{ backgroundColor: '#F84F31', color: "#FFFFFF" }}
        >
          Votre adresse email existe dans notre base de donnée, merci de réessayer avec une adresse valide<br />Ou connectez-vous <a href="/login">ici</a>
        </div>
      )
    }
  } else {
    if(locallang === "en") {
      return(
        <div
          className="alert text-center"
          role="alert"
          style={{ backgroundColor: '#F84F31', color: "#FFFFFF" }}
        >
          Your email address does not exist in our database, please retry with available email<br />Or create an account <a href="/sign-up">here</a>
        </div>
      )
      } else {
        return (
          <div
            className="alert text-center"
            role="alert"
            style={{ backgroundColor: '#F84F31', color: "#FFFFFF" }}
          >
            Votre adresse email n'existe pas dans notre base de donnée, merci de réessayer avec une adresse valide<br />Ou créer un compte <a href="/sign-up">ici</a>
          </div>
        )
      }
  }
}
// const placeholderPassword = () => {
//   if(language === 'en') {
//     return "Enter your password";
//   } else {
//     return "Entrez votre mot de passe";
//   }
// }
// const placeholderPasswordConfirm = () => {
//   if(language === 'en') {
//     return "Confirm your password";
//   } else {
//     return "Confirmez votre mot de passe";
//   }
// }
const placeholderEntreprise = () => {
  if(language === 'en') {
    return "";
  } else {
    return "";
  }
}
// const placeholderEmail = () => {
//   if(language === 'en') {
//     return "Enter your email";
//   } else {
//     return "Entrez votre adresse email";
//   }
// }
// const placeholderLastName = () => {
//   if(language === 'en') {
//     return "Enter your lastname";
//   } else {
//     return "Entrez nom de famille";
//   }
// }
// const placeholderFirstName = () => {
//   if(language === 'en') {
//     return "Enter your first name";
//   } else {
//     return "Entrez votre prénom";
//   }
// }
// const placeholderSiret = () => {
//   if(language === 'en') {
//     return "Enter your SIRET number";
//   } else {
//     return "Entrez le numéro de SIRET";
//   }
// }
const errorNotificationpassword = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "The password and password confirm are different, Please try again"
    )
    } else {
      return (
          "Le mot de passe et le mot de passe de confirmation sont différents"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "The password and password confirm are different, Please try again"
      )
      } else {
        return (
          "Le mot de passe et le mot de passe de confirmation sont différents"
        )
      }
  }
}
const errorNotificationAllFieldsEmpty = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "This field is empty"
    )
    } else {
      return (
          "Ce champ est vide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
          "This field is empty"
      )
      } else {
        return (
            "Ce champ est vide"
        )
      }
  }
}
const errorNotificationPasswordType = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "The password must contain at least one uppercase and 7 characters"
    )
    } else {
      return (
          "le mot de passe doit contenir au moins une majuscule et 7 caractères"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "The password must contain at least one uppercase and 7 characters"
      )
      } else {
        return (
          "le mot de passe doit contenir au moins une majuscule et 7 caractères"
        )
      }
  }
}
const successNotificationSiret = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "SIRET number found !"
    )
    } else {
      return (
          "Numéro de SIRET valide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "SIRET number found !"
      )
      } else {
        return (
          "Numéro de SIRET valide"
        )
      }
  }
}
const errorNotificationSiret = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
        "SIRET number not found !"
    )
    } else {
      return (
          "Numéro de SIRET invalide"
      )
    }
  } else {
    if(locallang === "en") {
      return(
        "SIRET number not found !"
      )
      } else {
        return (
          "Numéro de SIRET invalide"
        )
      }
  }
}
const checkResetSiret = () => {
  // console.log('siret', resetSiret);
  if( siret === '' && entreprise === '') {
    seterrorSiretEmpty(true);
  } else {
      if(siret.length === 14) {
        setnoVerifSiret(false);
        seterrorSiretString(false);
        seterrorSiretEmpty(false);
        setsuccessSiret(false);
        seterrorSiret(false);
        checkSiret();
      }else {
        // console.log('lol', errorSiretString)
        setnoVerifSiret(true);
        seterrorSiretString(false);
        setsuccessSiret(false);
        seterrorSiretEmpty(false);
        seterrorSiret(false);
      }
    }
}

const checkSiret = () => {
  if(siret === '' && entreprise === '') {
    console.log('siret', siret);
    seterrorSiretEmpty(true);
    setnoVerifSiret(false);
    seterrorSiretString(false);
    seterrorSiret(false);
    if(!successSiret) {
      setnoVerifSiret(true);
    }
  } else {
    if(siret.length === 14) {
      var config = {
        method: 'get',
        url: `https://api.insee.fr/entreprises/sirene/V3/siret/${siret}`,
        headers: {
          'Authorization': 'Bearer b417d363-ef5b-3a35-8cfb-e043729a5260',
          'Accept': 'application/json',
          
        }
      };
      
      axios(config)
      .then(function (response) {
        console.log(response);
        // console.log(JSON.stringify(response.data));
        // console.log(response.data.etablissement.nom_raison_sociale);
        if(response.data.etablissement.uniteLegale.denominationUniteLegale !== undefined) {
          seterrorSiret(false);
          setsuccessSiret(true);
          seterrorSiretString(false);
          setnoVerifSiret(false);
          setsiretName(response.data.etablissement.uniteLegale.denominationUniteLegale);
        }
      })
      .catch(function (error) {
        seterrorSiret(true);
        seterrorSiretString(false);
        setsuccessSiret(false);
        setnoVerifSiret(false);
        setsiretName('')
        console.log(error);
      });   
    } else {
      seterrorSiretEmpty(false);
      setnoVerifSiret(false);
      setsuccessSiret(false);
      seterrorSiretString(false);
      seterrorSiret(false);
    }
  }
}
  return (
    <React.Fragment>
      <div className="account-home-btn d-none d-sm-block">
        <Link to="/" className="text-white">
          <i className="mdi mdi-home h1"></i>
        </Link>
      </div>
      <section className="bg-account-pages vh-100">
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5}>
                  <Card className="account-card">
                    <CardBody>
                      <LanguageSelector setlanguage={setlanguage} />
                      <div className="text-center mt-3">
                        <h3 className="font-weight-bold">
                          <Link
                            to="/"
                            style={{ color: "#eb502e"}}
                            className="text-uppercase account-pages-logo"
                          > 
                            <img
                              src="../assets/images/logo/ipocamp.png"
                              style={{          
                                height: 35,
                                width: "110.63px",
                                margin: 0,
                              }}
                              alt="logo"
                            />
                          </Link>
                        </h3>
                      <Text tid="signup.subtitle" />
                      </div>
                      <div className="p-3">
                      <Formik 
                        style={{ backgroundColor: "#FFFFFF"}}
                        onSubmit={handleSubmit}
                      >
                          <Form
                            style={{width: "300px"}}
                          >
                            <FormGroup style={{ marginBottom: "20px" }}>
                              <Label 
                                htmlFor="firstname"
                                style={{
                                  fontSize: "10px",
                                  marginTop: "10px",
                                  marginLeft: '10px',
                                  marginBottom: "0px",
                                  height: '15px',
                                  color: "#787878",
                                  backgrounColor: '#FFFFFF',
                                  display: 'flex',
                                  position: 'relative',
                                  zIndex:10,
                                  width: '90px'
                                }}
                              >
                                <Text tid="signup.firstnamefield" />*
                              </Label>
                              <Field
                                onChange={(e) => {handleFirstName(e)}}
                                name="lastname"
                                // required
                                type="text"
                                className="form-control"
                                id="lastname"
                                // placeholder={placeholderFirstName()}
                              />
                            <div style={{ fontSize: "10px", marginLeft: "0px", marginTop:"-10px", marginBottom: errorAllEmptyFirstName ? "-20px" : "0px", color:"red"}}>
                              {errorAllEmptyFirstName === true ? (errorNotificationAllFieldsEmpty()) : null}
                            </div>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "20px" }}>
                              <Label 
                                  htmlFor="firstname"
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "10px",
                                    marginLeft: '10px',
                                    marginBottom: "0px",
                                    height: '15px',
                                    color: "#787878",
                                    backgrounColor: '#FFFFFF',
                                    display: 'flex',
                                    position: 'relative',
                                    zIndex:10,
                                    width: '90px'
                                  }}
                              >
                                <Text tid="signup.lastnamefield" />*
                              </Label>
                              <Field
                                onChange={(e) => {handleLastName(e)}}
                                name="lastname"
                                // required
                                type="text"
                                className="form-control"
                                id="lastname"
                                // placeholder={placeholderLastName()}
                              />
                              <div style={{ fontSize: "10px", marginLeft: "0px", marginTop:"-10px", marginBottom: errorAllEmptyLastName ? "-20px" : "0px", color:"red"}}>
                                {errorAllEmptyLastName === true ? (errorNotificationAllFieldsEmpty()) : null}
                              </div>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "20px" }}>
                              <Label 
                                  htmlFor="firstname"
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "20px",
                                    marginLeft: '10px',
                                    marginBottom: "0px",
                                    height: '15px',
                                    color: "#787878",
                                    backgrounColor: '#FFFFFF',
                                    display: 'flex',
                                    position: 'relative',
                                    zIndex:10,
                                    width: '90px'
                                  }}
                              >
                                <Text tid="signup.emailfield" />*
                              </Label>
                              <Field
                                onChange={handleEmail}
                                type="email"
                                name="email"
                                // required
                                className="form-control"
                                id="email"
                                // placeholder={placeholderEmail()}
                              />
                              <div style={{ fontSize: "10px", marginLeft: "0px", marginTop:"-10px", marginBottom: errorAllEmptyEmail? "-20px" : "0px", color:"red"}}>
                                {errorAllEmptyEmail === true ? (errorNotificationAllFieldsEmpty()) : null}
                              </div>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "20px" }}>
                              <Label 
                                  htmlFor="firstname"
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "10px",
                                    marginLeft: '10px',
                                    marginBottom: "0px",
                                    height: '15px',
                                    color: "#787878",
                                    backgrounColor: '#FFFFFF',
                                    display: 'flex',
                                    position: 'relative',
                                    zIndex:10,
                                    width: '90px'
                                  }}
                              >
                                <Text tid="signup.firmfield" />
                              </Label>
                              <Field
                                disabled={siret !== 0 ? true : false}
                                onChange={(e) => handleEntreprise(e)}
                                type="entreprise"
                                name="entreprise"
                                // required
                                className="form-control"
                                id="entreprise"
                                placeholder={entreprise === '' && siretName === '' ? (placeholderEntreprise()) : entreprise === '' ? siretName : entreprise}
                              />
                              <div style={{ fontSize: "10px", marginLeft: "0px", marginTop:"-10px", marginBottom: errorAllEmptyEntreprise ? "-20px" : "0px", color:"red"}}>
                                {errorAllEmptyEntreprise === true ? (errorNotificationAllFieldsEmpty()) : null}
                              </div>
                            </FormGroup>
                            <Row>
                              <Col style={{ height: '50px', marginBottom: '20px' }}>
                                <MediaQuery minWidth={750}>
                                  <FormGroup style={{ marginBottom: '20px' }}>
                                    <Label 
                                        htmlFor="firstname"
                                        style={{
                                          fontSize: "10px",
                                          marginLeft: '10px',
                                          marginBottom: "10px",
                                          height: '15px',
                                          color: "#787878",
                                          backgrounColor: '#FFFFFF',
                                          display: 'flex',
                                          position: 'relative',
                                          zIndex:10,
                                          width: '90px'
                                        }}
                                    >
                                      <Text tid="signup.siretfield" />
                                    </Label>
                                    <Field
                                      // disabled={entreprise !== '' ? true : false}
                                      onChange={(e) => {handleSiret(e)}}
                                      name="lastname"
                                      // required
                                      style={{ width: "300px", marginTop: "-10px" }}
                                      type="text"
                                      className="form-control"
                                      id="lastname"
                                      // placeholder={placeholderSiret()}
                                    />
                                  </FormGroup>
                                </MediaQuery>
                                <MediaQuery maxWidth={750}>
                                  <FormGroup style={{ width: "100%", marginBottom: "20px" }}>
                                    <Label 
                                        htmlFor="firstname"
                                        style={{
                                          fontSize: "10px",
                                          marginLeft: '10px',
                                          height: '15px',
                                          color: "#787878",
                                          backgrounColor: '#FFFFFF',
                                          display: 'flex',
                                          position: 'relative',
                                          zIndex:10,
                                          width: '90px'
                                        }}
                                    >
                                      <Text tid="signup.siretfield" />
                                    </Label>
                                    <Field
                                      onChange={(e) => {handleSiret(e)}}
                                      name="lastname"
                                      // required
                                      style={{ width: "210px", marginTop: "-10px"}}
                                      type="text"
                                      className="form-control"
                                      id="lastname"
                                      // placeholder={placeholderSiret()}
                                    />
                                    {/* <div style={{ fontSize: "10px", marginLeft: "0px", marginTop:"-10px", color:"red", marginBottom: '20px'}}>
                                      {errorEmptySiret === true ? (errorNotificationAllFieldsEmpty()) : null}<br />
                                      {successSiret === true ? (successNotificationSiret()) : null}<br />
                                      {errorSiret === true ? (errorNotificationSiret()) : null}<br />
                                    </div> */}
                                  </FormGroup>
                                </MediaQuery>  
                             </Col>
                             <Col>
                              <div style={{ marginLeft: '-23px' }}>
                              <Button
                                disabled={entreprise !== '' ? true : false}
                                onClick= {checkSiret}
                                className=''
                                size="lg"
                                style={{
                                  marginTop: '15px',
                                  color: '#000000',
                                  backgroundColor: "#FFFFFF",
                                  border: "none",
                                  borderRadius: "20px"
                                }}
                                // onClick={entreprise === '' ? checkResetSiret : null}
                              >
                              <span style={{ width: '100%' }} className="label">
                                <Text tid="signup.validationsiret" />
                              </span>
                            </Button>
                              </div>
                            </Col>
                             
                            <div style={{ fontSize: "10px", marginLeft: "0px", marginTop:"-5px", marginBottom: "0px", color: successSiret? ("green") : ("red")}}>
                              {errorEmptySiret === true ? (errorNotificationAllFieldsEmpty()) : null}
                              {successSiret === true ? (successNotificationSiret()) : null}
                              {errorSiret === true ? (errorNotificationSiret()) : null}
                              {errorSiretString === true ? (errorNotificationSiretString(language)) : null}
                              {noVerifSiret ? (errorNotificationNoVerifSiret(language)) : null}
                            </div>
                            </Row>
                            <FormGroup style={{ marginBottom: "20px"}}>
                              <Label 
                                  htmlFor="firstname"
                                  style={{
                                    fontSize: "10px",
                                    marginLeft: '10px',
                                    marginBottom: "0px",
                                    height: '15px',
                                    color: "#787878",
                                    backgrounColor: '#FFFFFF',
                                    display: 'flex',
                                    position: 'relative',
                                    zIndex:10,
                                    width: '90px'
                                  }}
                              >
                                <Text tid="signup.passfield" />*
                              </Label>
                              <Row>
                                <Col>
                              <Field
                                onChange={handlePassword}
                                type={showPasswordTwo ? "text" : "password"}
                                name="password"
                                // required
                                style={{ width: '298px'}}
                                className="form-control"
                                id="userpassword"
                                // placeholder={placeholderPassword()}
                              />
                              </Col>
                              <Col>
                              <img
                              onClick={()=> {
                                 setshowPasswordTwo(!showPasswordTwo)
                              }}
                              style={{
                                position: "relative",
                                zIndex:10,
                                width: "15px",
                                height: "15px",
                                marginLeft: "260px",
                                marginTop: "-100px",
                                cursor: "pointer"
                              }}
                              src="assets/images/icons/visibility.png"
                              alt="eye"
                            />
                            </Col>
                            </Row>
                              <div style={{ fontSize: "10px", marginLeft: "0px", marginTop:"-35px", marginBottom: errorEmptyPassword || badPasswordType ? "50px" : "0px", color:"red"}}>
                                {errorEmptyPassword === true ? (errorNotificationAllFieldsEmpty()) : null}
                                {badPasswordType === true ? (errorNotificationPasswordType()) : null}
                              </div>
                            </FormGroup>
                            <FormGroup>
                              <Label 
                                htmlFor="firstname"
                                style={{
                                  fontSize: "10px",
                                  marginTop: '-50px',
                                  marginLeft: '10px',
                                  marginBottom: "0px",
                                  height: '15px',
                                  color: "#787878",
                                  backgrounColor: '#FFFFFF',
                                  display: 'flex',
                                  position: 'relative',
                                  zIndex:10,
                                  width: '150px'
                                }}
                              >
                                <Text tid="signup.confirm" />*
                              </Label>
                              <Row>
                                <Col>
                              <Field
                                onChange={handlePasswordConfirm}
                                type={showPasswordOne ? "text" : "password"}
                                name="password"
                                // required
                                className="form-control"
                                id="userpassword"
                                // placeholder={placeholderPasswordConfirm()}
                              />
                              </Col>
                              <Col>
                              <img
                                onClick={()=> {
                                  setshowPasswordOne(!showPasswordOne)
                                }}
                                style={{
                                  position: "relative",
                                  zIndex:10,
                                  width: "15px",
                                  height: "15px",
                                  marginLeft: "260px",
                                  marginTop: "-100px",
                                  cursor: "pointer"
                                }}
                                src="assets/images/icons/visibility.png"
                                alt="eye"
                            />
                            </Col>
                            </Row>
                              <div style={{ fontSize: "10px", marginLeft: "0px", marginTop:"-35px", color:"red"}}>
                                {errorEmptyPassword === true ? (errorNotificationAllFieldsEmpty()) : null}
                                {errorpasswordNotSame === true ? (errorNotificationpassword()) : null}
                              </div>
                            </FormGroup>

                            {/* <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="customControlInline"
                              />{" "}
                              <Label
                                className="custom-control-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </Label>
                            </div> */}

                            { error === true ? (errorNotification()) : null}
                            <div className="d-grid mt-3">
                              <Button
                                // onClick={userRegister}
                                onClick={handleSubmit}
                                // onClick={handleVariable}
                                color="none"
                                className="btn bg-color-chart text-chart"
                              >
                                <Text tid="signup.validationbutton" />
                              </Button>
                            </div>

                            <div className="mt-4 mb-0 text-center">
                              <p className="mb-0">
                              <Text tid="signup.account" />{" "}
                                <Link to="/login" style={{ color: "#EB502E"}}>
                                <Text tid="signup.account-redirect" />
                                </Link>
                              </p>
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Signup;
