import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { Text } from '../../containers/Language';

//Import Section Title
import SectionTitle from "../common/section-title";
import ProcessBox from "./ProcessBox";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processes: [
        {
          icon: "pe-7s-mail",
          title: <Text tid="work.title1" />,
          desc: <Text tid="work.subtitle1" />,
        },
        {
          icon: "pe-7s-key",
          title: <Text tid="work.title2" />,
          desc: '',
        },
        {
          icon: "pe-7s-rocket",
          title: <Text tid="work.title3" />,
          desc: <Text tid="work.subtitle3" />,
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="pt-5" id="process" style={{ backgroundColor: "#fdf8f4" }}>
          <Container>
            {/* section title */}
            <SectionTitle
              title={<Text tid="work.title" />}
              // desc={<Text tid="work.subtitle" />}
            />

            <Row className="mb-1">
              <Col lg={6} className="text-center process-left-icon-1" style={{ backgroundColor: '#000000' }}>
                <i className="pe-7s-angle-right" style={{ backgroundColor: '#FFFFFF' }}></i>
              </Col>
              <Col lg={6} className="text-center process-left-icon-2">
                <i className="pe-7s-angle-right"></i>
              </Col>
            </Row>
            <Row className="mt-5">
              <ProcessBox processes={this.state.processes} />
                <div className="text-center">
                <Link
                  to="/documentation"
                  className="btn bg-color-chart text-chart  waves-light waves-effect mt-5"
                  style={{ backgroundColor: '#eb502e'}}
                >
                  <Text tid="work.validationbutton" /> <i className="mdi mdi-arrow-right"></i>
                </Link>
                  <a
                    href="mailto:contact@ipocamp.fr?subject=API%20demande%20de%20devis"
                    target="_blank"
                    className="btn bg-color-chart text-chart  waves-light waves-effect mt-5"
                    style={{ backgroundColor: '#eb502e', marginLeft: '30px' }}
                  >
                    <Text tid="work.contactus" /> <i className="mdi mdi-arrow-right"></i>
                  </a>
                </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
