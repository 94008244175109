import React, {useState, useEffect} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    TabPane,
    TabContent,
} from 'reactstrap';

// import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../../components/Navbar/Navbar';
import Footer from "../../components/Footer/footer";
// import Modal from '../../components/containers/ui/Modal';
import { Colxx } from "../../components/common/CustomBootstrap";
import SimpleLineIcon from 'react-simple-line-icons';
import { Button, Fade } from 'reactstrap';
import axios from 'axios';
import { backLink } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';
import MediaQuery from 'react-responsive';

import { getCurrentUser } from '../../helpers/Utils';

import { Text } from '../../containers/Language';
// import { formatDate } from '@formatjs/intl';
// import { YoutubeSearchedFor } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    main: {
      width: "100%",
      height: "1366px",
      verticalAlign: "middle",
      position: "absolute",
      margin: "0"
    },
    verticalCenter: {
        marginTop: "20px",
      },
    footer: {
      bottom: '0',
      width: "100%",
      position: 'static',
      marginTop: '160px',
      backgroundColor: "#fdf8f4"
    },
    footerTab: {
      bottom: '0',
      width: "100%",
      position: 'static',
      backgroundColor: "#fdf8f4"
    },
    footerTabPro: {
      bottom: '0',
      width: "100%",
      position: 'static',
      marginTop: "580px",
      backgroundColor: "#fdf8f4"
    }
  }));
  
const Dashboards = (props) => {
    const [fadeIn, setFadeIn] = useState(false);
    const [language, setlanguage] = useState();
    // const [ModalAlert, setModalAlert] = useState(false);
    const [accessToken, setaccessToken] = useState(false);
    const [addApiKey, setaddApiKey] = useState(0);
    const [clientId, setclientId] = useState('');
    // const [apiKey, setapiKey] = useState();
    const [loader, setloader] = useState(false);
    const [apiArray, setapiArray] = useState([]);
    const [apiKeychange, setapiKeychange] = useState(0)
    const [unsubscribe, setunsubscribe] = useState(false);
    const [endingPeriod, setendingPeriod] = useState();

    const [blackButton, setblackButton] = useState("#FFFFFF")

    const [activeTab] = useState('profile');
    const classes = useStyles();

    const toggle = () => setFadeIn(!fadeIn);

    const userProfile = (client, token) => {
      axios({
        method: 'get',
        url: `${backLink}/clients/${client}`,
        headers: {
          'Authorization': `Bearer ${token}`
        },})
        .then(function (response) {
          // console.log("userdata hello", response.data)
          // console.log("ending period hello", response.data.stripe.cancel_at_period_end);
          props.setfirst_name(response.data.first_name);
          props.setlast_name(response.data.last_name);
          setunsubscribe(response.data.stripe.cancel_at_period_end);
          setendingPeriod(response.data.deposits.current_monthly_ending_at);
          const item = {
            id: response.data._id,
            api_key: response.data.api_key,
            email : response.data.email,
            first_name: response.data.first_name,
            last_deposit: response.data.deposits.last_deposit,
            current_month_deposits: response.data.deposits.current_month_deposits,
            last_name: response.data.last_name,
            permission_level: response.data.permission_level,
            total_deposits: response.data.deposits.total_deposits,
            company: response.data.company.name,
            siret: response.data.company.siret,
            allowed_monthly_deposits: response.data.deposits.allowed_monthly_deposits,
            subscription_created_at : response.data.stripe.subscription_created_at

          };
          setCurrentUser(item);
          // console.log("item.api_key lllll", item);
          // console.log("getcurrent user", getCurrentUser());
          setapiArray(item.api_key);
          setloader(true);
        })
        .catch(function (error) {
          console.log(error);
          props.setdeconnexion(true);
      });
  }
    useEffect(() => {
      // console.log('props', props);
      if(apiKeychange > 0) {
        getApiKey();
        getApiKeyResponsive();
      }
      if(clientId === "" || clientId === null) {
        setaccessToken(window.localStorage.getItem('accessToken'));
        setclientId(localStorage.getItem('client_id'))
        // console.log('accessToken', accessToken);
        // console.log('clientId', clientId);
        // console.log('permissionLevel', accessToken);
      } else {
        // console.log("hello it's me");
        // console.log("clientId", clientId);
        // console.log("accessToken", accessToken);
        userProfile(clientId, accessToken);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, clientId, apiKeychange])

  const getArray = (array) => {
      setapiArray(array);
  }
  const getApiKey = () => {
    // console.log('apikeys', apiKeys)
    return (
      apiArray.map((api, index) => (
        <Row key={index}>
          <Col>
            <div style={{width: "500px", display: 'flex', justifyContent: "left"}}>
            <Fade in={fadeIn} tag="h5" className="mt-3" style={{ color: "#8f8f8f"}}>
              {api}
            </Fade>
            </div>
          </ Col>
          <Col>
            <Fade in={fadeIn} tag="h5" className="mt-3" onClick={() => revokedApi(api, index)} style={{ color: "#8f8f8f", cursor: 'pointer' }}>
              <SimpleLineIcon name="trash" size="Small" color="#eb502e"/>
            </Fade>
          </ Col>
        </ Row>
    ))
    )
  }
  const getApiKeyResponsive = () => {
    // console.log('apikeys', apiKeys)
    return (
      apiArray.map((api, index) => (
        <Row key={index}>
          <Col>
            <div style={{width: "200px", display: 'flex', justifyContent: "left"}}>
            <Fade in={fadeIn} tag="h5" className="mt-3" style={{ color: "#8f8f8f", fontSize: '6px'}}>
              {api}
            </Fade>
            </div>
          </ Col>
          <Col>
            <Fade in={fadeIn} tag="h5" className="mt-3" onClick={() => revokedApi(api, index)} style={{ color: "#8f8f8f", cursor: 'pointer' }}>
              <SimpleLineIcon name="trash" size="Small" color="#eb502e" style={{ cursor: "pointer"}} />
            </Fade>
          </ Col>
        </ Row>
    ))
    )
  }
  useEffect(() => {
    if(addApiKey > 0) {
      axios({
        method: 'patch',
        url: `${backLink}/clients/create/api_key`,
        headers: { 
          'Authorization': `Bearer ${accessToken}`
        }
      })
      .then(function (response) {
        // console.log('hello', JSON.stringify(response.data));
        userProfile(clientId, accessToken);
        // console.log('getCurrentUser()', getCurrentUser().api_key)
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }, [clientId, accessToken, addApiKey])

  const revokedApi = (api, index) => {
    let array = apiArray
      axios({
        method: 'patch',
        url: `${backLink}/clients/revoke/api_key`,
        headers: { 
          'Authorization': `Bearer ${accessToken}`, 
          'Content-Type': 'application/json'
        },
        data : {
          "api_key": api
        }
      })
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        array.splice(index, 1)
        if(apiArray.length !== 0) {
          getArray(array);
        } else {
          userProfile(clientId, accessToken);
        }
        setapiKeychange(apiKeychange + 1);
        //console.log("apiArray", array)
      })
      .catch(function (error) {
        // console.log("non");
        console.log(error);
      })
  }
  const formatDate = (lang) => {
    // console.log('language', language);
    let day;
    let month;
    let year;
    let defaultLanguage = localStorage.getItem('rcml-lang');
    day = endingPeriod.split('-')[2].split('T')[0];
    month = endingPeriod.split('-')[1];
    year = endingPeriod.split('-')[0];
    // console.log('day', day);
    // console.log('month', month);
    // console.log('year', year);
    if(language !== undefined) { 
      if(lang === 'fr') {
        return `${day}/${month}/${year}`
      }
      if (lang === 'en') {
        return `${year}/${month}/${day}`
      }
    } else { 
      if(defaultLanguage === 'fr') {
        return `${day}/${month}/${year}`
      }
      if (defaultLanguage === 'en') {
        return `${year}/${month}/${day}`
      }

    }
  }
  const noPaidByStripe = () => {
    if(
      getCurrentUser().permission_level === 1 &&
        (getCurrentUser().stripe === null ||
        getCurrentUser().stripe === undefined ||
          (getCurrentUser().stripe &&
            (getCurrentUser().stripe.paid=false ||
              (getCurrentUser().stripe.paid==undefined ||
              getCurrentUser().stripe.paid==null))))) {
                return '\u221e';
              } else {
                return getCurrentUser().allowed_monthly_deposits - getCurrentUser().total_deposits;
              }
  }
  if(loader) {
  return (
    <React.Fragment>
      <section className="bg-account-pages vh-100">
           <Navbar setlanguage={setlanguage} first_name={props.first_name} last_name={props.last_name} />
        <div style={{ backgroundColor: "#F3CB7D" }}>
        <div className="display-table" style={{ width: "100%", height: "auto", backgroundColor: "#fdf8f4"}}>
          <div className="" style={{ marginTop: "130px", backgroundColor: "#fdf8f4" }}>
          <TabContent activeTab={activeTab} className="mb-3" style={{ marginTop: "150px"}}>
            <TabPane tabId="profile">
              <Row
                md="1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  marginTop: "15px",
                  marginBottom: "-20px"
                }}
              >
                  <Colxx
                    sm="4"
                    className="mb-3"
                    style={{
                      minWidth: '285px',
                      maxWidth: '300px',
                    }}
                    md="4"
                  >
                    <Card style={{ height: '200px', marginTop: '15px', borderRadius: "10px" }}>
                      <CardBody
                      className={`text-center ${classes.verticalCenter}`}
                      >
                        <SimpleLineIcon name="docs" size="Large" color="#eb502e" style={{ fontSize: "46px" }} />
                          <br />
                          <div
                            style={{ whiteSpace: 'pre-line', color: "#8f8f8f", fontWeight: "400", marginTop:"-5px"}}
                            className="card-text font-weight-semibold mb-0"
                          >
                            <Text tid="dashboard.total-deposit" />
                          </div>
                          <p style={{ whiteSpace: 'pre-line', color: "#eb502e", fontWeight: "300", fontSize: "25px", marginTop: "5px"}} className="lead text-center">
                              {getCurrentUser().allowed_monthly_deposits === null || getCurrentUser().allowed_monthly_deposits === undefined? 0 : getCurrentUser().allowed_monthly_deposits}
                          </p>
                      </CardBody>
                    </Card>
                  </Colxx>
                  <Colxx
                    sm="4"
                    className="mb-3"
                    style={{
                      minWidth: '285px',
                      maxWidth: '300px',
                    }}
                    md="4"
                  >
                    <Card style={{ height: '200px', marginTop: '15px', borderRadius: "10px", verticalAlign: "middle" }}>
                      <CardBody
                        className={`text-center ${classes.verticalCenter}`}
                      >
                        <SimpleLineIcon name="speedometer" size="Large" color="#eb502e" style={{ fontSize: "46px" }} />
                          <br />
                          <div
                            style={{ whiteSpace: 'pre-line', color: "#8f8f8f", fontWeight: "400", marginTop:"-5px"}}
                            className="card-text font-weight-semibold mb-0"
                          >
                            <Text tid="dashboard.month-deposit" />
                          </div>
                          <p style={{ whiteSpace: 'pre-line', color: "#eb502e", fontWeight: "300", fontSize: "25px", marginTop: "5px"}} className="lead text-center">
                              {getCurrentUser().total_deposits}
                          </p>
                        </CardBody>
                      </Card>
                    </Colxx>
                    <Colxx
                      sm="4"
                      className="mb-3"
                      style={{
                        minWidth: '285px',
                        maxWidth: '300px',
                      }}
                      md="4"
                    >
                      <Card style={{ height: '200px', marginTop: '15px', borderRadius: "10px" }}>
                        <CardBody
                          className={`text-center ${classes.verticalCenter}`}
                        >
                        <SimpleLineIcon name="energy" size="Large" color="#eb502e" style={{ fontSize: "46px" }} />
                          <br />
                          <div
                            style={{ whiteSpace: 'pre-line', color: "#8f8f8f", fontWeight: "400", marginTop:"-5px"}}
                            className="card-text font-weight-semibold mb-0"
                          >
                            <Text tid="dashboard.remain" />
                          </div>
                          <p style={{ whiteSpace: 'pre-line', color: "#eb502e", fontWeight: "300", fontSize: "25px", marginTop: "5px"}} className="lead text-center">
                              {noPaidByStripe()}
                          </p>
                        </CardBody>
                      </Card>
                    </Colxx>
                </Row>
                <Row
                md="1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                  <Colxx
                    sm="4"
                    className={`text-center ${classes.verticalCenter}`}
                    style={{
                      minWidth: '285px',
                      maxWidth: '300px',
                      marginTop: "30px"
                    }}
                    md="4"
                  >
                    <Card style={{ height: '200px', borderRadius: "10px" }}>
                      <CardBody
                        className={`text-center ${classes.verticalCenter}`}
                      >
                        <SimpleLineIcon name="user" size="Large" color="#eb502e" style={{ fontSize: "46px" }} />
                          <br />
                          <div
                            style={{ whiteSpace: 'pre-line', color: "#8f8f8f", fontWeight: "400", marginTop:"-5px"}}
                            className="card-text font-weight-semibold mb-0"
                          >
                            <Text tid="dashboard.statut" />
                          </div>
                          <p style={{ whiteSpace: 'pre-line', color: "#eb502e", fontWeight: "300", fontSize: "25px", marginTop: "5px"}} className="lead text-center">
                            {getCurrentUser()
                            .permission_level === 0? <Text tid="dashboard.statut-member" /> : <Text tid="dashboard.statut-premium" />}
                          </p>
                          {unsubscribe === false || unsubscribe === undefined ? null : (
                            <p style={{ whiteSpace: 'pre-line', color: "#8f8f8f", fontWeight: "400", marginTop:"-5px"}}><Text tid="dashboard.ending" /> {formatDate(language)}</p>
                          )}
                          
                      </CardBody>
                    </Card>
                  </Colxx>
                    <MediaQuery minWidth={900}>
                      <Colxx
                        sm="4"
                        className={`text-center ${classes.verticalCenter}`}
                        style={{
                          marginRight: '300px',
                          minWidth: '285px',
                          maxWidth: '300px',
                          marginTop: "30px"
                        }}
                        md="4"
                      >
                        <Card style={{ height: "auto", minHeight: "200px", borderRadius: "10px", width: "575px" }}>
                          <CardBody
                            className={`text-center`}
                          >
                            <div style={{ justifyContent: "right", display:"flex"}}>
                              <Button
                                disabled={getCurrentUser().permission_level === 0 ? true : false}
                                onClick={() => {
                                  setFadeIn(true);
                                  setaddApiKey(addApiKey + 1)}
                                } 
                                style={{
                                  fontWeight: "500",
                                  marginBottom: "20px",
                                  marginTop: "-10px",
                                  color: "#eb502e",
                                  backgroundColor: "#FFFFFF",
                                  border: "none",
                                  cursor: "pointer"
                                }}
                              >
                                <Text tid="dashboard.addapi" />
                              </Button>
                            </div>
                            <div>
                              <Button
                                disabled={getCurrentUser().permission_level === 0 ? true : false}
                                style={{
                                  backgroundColor:"#eb502e",
                                  border: "none"
                                }}
                                
                                onClick={toggle}
                              >
                                {getCurrentUser().permission_level === 1 ? <Text tid="dashboard.button-api-key" /> : <Text tid="dashboard.suscribe-button-api-key" />}
                              </Button>
                              {fadeIn? getApiKey() : null}
                            </div>
                          </CardBody>
                        </ Card>
                      </Colxx>
                    </ MediaQuery>
                    <MediaQuery maxWidth={900}>
                    <Colxx
                        sm="4"
                        className={`text-center ${classes.verticalCenter}`}
                        style={{
                          minWidth: '285px',
                          maxWidth: '300px',
                          marginTop: "35px",
                          maxHeight: "1000px"
                        }}
                        md="4"
                      >
                        <Card style={{ minHeight: "200px", maxHeight: "2000px", borderRadius: "10px", width: "auto" }}>
                          <CardBody
                            className={`text-center`}
                          >
                            <div className="text-center process-box" style={{ justifyContent: "right", display:"flex"}}>
                              {getCurrentUser().permission_level === 1 ? ( 
                              <i
                                className="pe-7s-plus text-primary"
                                onClick={() => {
                                  setFadeIn(true);
                                  setaddApiKey(addApiKey + 1)}
                                  } 
                                  style={{
                                    fontWeight: "500",
                                    marginBottom: "20px",
                                    marginTop: "-10px",
                                    color: "#000000",
                                    backgroundColor: "#FFFFFF",
                                    border: "none",
                                    cursor: "pointer"
                                }}
                              />) : null}
                            </div>
                            <div style={{ marginTop: getCurrentUser().permission_level === 1 ? "-10px" : "60px"}}>
                              <Button disabled={() => getCurrentUser().permission_level === 0 ? true : false} style={{ backgroundColor:"#eb502e", border: "none", fontSize: '10px' }} onClick={toggle}><Text tid="dashboard.button-api-key" /></Button>
                              {fadeIn? getApiKeyResponsive() : null}
                            </div>
                          </CardBody>
                        </ Card>
                      </Colxx>
                    </ MediaQuery>
                  {/* <Colxx
                    sm="4"
                    key="1"
                    className={`text-center ${classes.verticalCenter}`}
                    style={{
                      minWidth: '285px',
                      maxWidth: '300px',
                    }}
                    md="4"
                  >
                    </ Colxx> */}
              </Row>
              {/* <MediaQuery maxWidth={900}>
                <MediaQuery minWidth={769}>
                    <div 
                      style={{
                        marginLeft: '70px',
                        marginTop: "50px",
                        marginBottom:"100px",
                        width: '250px',
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor:"#eb502e",
                          border: "none",
                          margin: "auto",
                          color: blackButton
                      }}
                        onMouseOver={() => setblackButton('#000000')}
                        onMouseLeave={() => setblackButton("#FFFFFF")}
                        // onClick={() => history.push("/#" + props.navItems[2].idnm)}
                        onClick={() => window.location.href = "/#pricing"}
                      >
                        {getCurrentUser().permission_level === 1 ? <Text tid="dashboard.modify" /> : <Text tid="dashboard.subscription" />}&nbsp;<i className="mdi mdi-arrow-right"></i>
                      </Button>
                    </div>
                  </MediaQuery>
                  <MediaQuery maxWidth={769}>
                    <div 
                      style={{
                        display:"flex",
                        margin: "auto",
                        marginTop: "50px",
                        marginBottom:"100px",
                        width: '250px',
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor:"#eb502e",
                          border: "none",
                          margin: "auto",
                          color: blackButton
                      }}
                        onMouseOver={() => setblackButton('#000000')}
                        onMouseLeave={() => setblackButton("#FFFFFF")}
                        // onClick={() => history.push("/#" + props.navItems[2].idnm)}
                        onClick={() => window.location.href = "/#pricing"}
                      >
                        {getCurrentUser().permission_level === 1 ? <Text tid="dashboard.modify" /> : <Text tid="dashboard.subscription" />}&nbsp;<i className="mdi mdi-arrow-right"></i>
                      </Button>
                    </div>
                  </MediaQuery>
                </MediaQuery>
                <MediaQuery minWidth={900}>
                  <Button
                    style={{
                      backgroundColor:"#eb502e",
                      border: "none",
                      display: "flex",
                      margin: "auto",
                      width: "auto",
                      marginTop: "50px",
                      marginBottom:"100px",
                      color: blackButton
                      }}
                     onMouseOver={() => setblackButton('#000000')}
                     onMouseLeave={() => setblackButton("#FFFFFF")}
                    // onClick={() => history.push("/#" + props.navItems[2].idnm)}
                    onClick={() => window.location.href = "/#pricing"}
                  >
                    {getCurrentUser().permission_level === 1 ? <Text tid="dashboard.modify" /> : <Text tid="dashboard.subscription" />}&nbsp;<i className="mdi mdi-arrow-right"></i>
                  </Button>
                </MediaQuery> */}
                <div style={{height: '100px' }} />
              </TabPane>
          </TabContent>
          <MediaQuery minWidth={1026}>
           <div className={classes.footer}>
            <Footer />
          </div>
          </MediaQuery>
          <MediaQuery maxWidth={1026}>
          <MediaQuery maxWidth={376}>
           <div className={classes.footerResponsive}>
            <Footer />
          </div>
          </MediaQuery>
          <MediaQuery minWidth={376}>
            <MediaQuery maxWidth={1023}>
              <div className={classes.footerResponsive}>
                <Footer />
              </div>
            </MediaQuery>
            <MediaQuery minWidth={1023}>
              <div className={classes.footerTabPro}>
                <Footer />
              </div>
            </MediaQuery>
            </MediaQuery>
          </MediaQuery>
         </div>
        </div>
      </div>
      </section>
    </React.Fragment>
  );
  } else {
    return null;
  }
};

export default Dashboards;