import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import MediaQuery from 'react-responsive';
import { makeStyles } from '@material-ui/core/styles';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import Footer from "../../components/Footer/footer";

const useStyles = makeStyles((theme) => ({
    main: {
      backgroundColor: "#fdf8f4",
      width: '900px',
      // height: "100%",
      minHeight: '970px',
      display: "flex",
      margin: "auto",
      // height: "4650px",
      position: "relative",
    },
    footer: {
      marginTop: "260px",
      width: '100%',
      backgroundColor: "#fdf8f4",
    },
    footerResponsive: {
      display: 'table',
      bottom: '0',
      position: 'static',
      marginTop: "900px",
      width: '900px',
      backgroundColor: "#fdf8f4",
    },
    footerTab : {
      display: 'table',
      bottom: '0',
      position: 'static',
      marginTop: "135px",
      width: '900px',
      backgroundColor: "#fdf8f4",
    },
    footerTabPro : {
      display: 'table',
      bottom: '0',
      position: 'static',
      marginTop: "300px",
      width: '900px',
      backgroundColor: "#fdf8f4",
    },
    verticalCenter: {
        marginTop: "20px",
      }
  }));

const Documentation = (props) => {
    const classes = useStyles();
  
  return (
    <React.Fragment>
      <section>
        <Navbar first_name={props.first_name} last_name={props.last_name} />
        <div className="display-table" style={{ width: "100%", height: "auto", backgroundColor: "#fdf8f4"}}>
         <div className={classes.main} style={{ backgroundColor: "#fdf8f4"}}>
            <MediaQuery minWidth={780}>
              <div style={{ margin: "auto", marginTop: "100px" }}>
                <SwaggerUI
                  url="/assets/swagger/nlecoufl-Api-Ipocamp-1.0-resolved.json" 
                  withCredentials
                />
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={780}>
              <div style={{marginTop: "150px", backgroundColor: "#fdf8f4"}}>
                <SwaggerUI
                  url="/assets/swagger/nlecoufl-Ipocamp-1.0.0-resolved.json" 
                  withCredentials
                />
              </div>
            </MediaQuery>
          </div>
          <MediaQuery minWidth={1026}>
           <div className={classes.footer}>
            <Footer />
          </div>
          </MediaQuery>
          <MediaQuery maxWidth={1026}>
          <MediaQuery maxWidth={376}>
           <div className={classes.footerResponsive}>
            <Footer />
          </div>
          </MediaQuery>
          <MediaQuery minWidth={376}>
            <MediaQuery maxWidth={1023}>
              <div className={classes.footerTab}>
                <Footer />
              </div>
            </MediaQuery>
            <MediaQuery minWidth={1023}>
              <div className={classes.footerTabPro}>
                <Footer />
              </div>
            </MediaQuery>
            </MediaQuery>
          </MediaQuery>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Documentation;