import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { LanguageProvider } from './containers/Language';
import Index1 from "./pages/Index1/Index1";

// import { Text } from './containers/Language';
//Auth Pages
import Signup from "./pages/Auth/Signup";
import Login from "./pages/Auth/Login";
import PasswordForget from "./pages/Auth/PasswordForget";
import ResetPassword from "./pages/Auth/ResetPassword";
import VerificationCode from "./pages/Auth/verificationCode";
import Social from "./pages/Profil/social";
import Dashboard from "./pages/Dashboard/Dashboard";
// import Swagger from "./pages/Dashboard/Swagger";
import { ProtectedRoute } from './helpers/authHelper';
import Unauthorized from './pages/Unauthorized';
import PaySuccess from "./pages/Paysuccess/PaySuccess";
import Documentation from "./pages/Documentation/documentation";
import ErrorNotFound from "./pages/errorNotFound/ErrorNotFound";
import { getCurrentUser } from "./helpers/Utils";

import { backLink } from './constants/defaultValues';

import axios from 'axios';

import { delete_cookie } from 'sfcookies';
import { useHistory } from 'react-router-dom';

// import LanguageSelector from './components/LanguageSelector';
function App() {
  const [login, setlogin] = useState()
  const [priceId, setpriceId] = useState();
  const [getPay, setgetPay] = useState(false);
  const [first_name, setfirst_name] = useState();
  const [last_name, setlast_name] = useState();
  const [deconnexion, setdeconnexion] = useState(false);
  // const [navItems, setNavItems] = useState([
  //   { id: 1, idnm: "home", navheading: <Text tid="navbar.home" /> },
  //   { id: 2, idnm: "services", navheading: <Text tid="navbar.service" /> },
  //   { id: 4, idnm: "pricing", navheading: <Text tid="navbar.pricing" /> },
  //   { id: 5, idnm: "process", navheading: <Text tid="navbar.process" /> },
  // ],)

  let history = useHistory();

  useEffect(() => {
    if(deconnexion) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('client_id');
      localStorage.removeItem('permission_level');
      localStorage.removeItem('current_user');
      localStorage.removeItem('confirmToken');
      localStorage.removeItem('tokenExp');
      delete_cookie('refreshToken')
      localStorage.setItem('login', false);
      history.push('/login');
      // window.location.reload();
    }
    // console.log("propsysgetcurrentuser", getCurrentUser());
    if ( localStorage.getItem('login') !== null) {
      setlogin(localStorage.getItem('login'))
      // console.log('login', login)
    } else {
      localStorage.setItem('login', false);
    }
  }, [login, deconnexion])
  const stripeRequest = () => {
    let accessToken = localStorage.getItem('accessToken')
    axios({
      method: 'get',
      url: `${backLink}/clients/stripe/price${priceId}`,
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      },
    })
    .then(function (response) {
      // console.log('response', response);
      // console.log('response.data.url', response.data.url);
      if(response.status === 200) {
        if(response.data.url === undefined) {
          window.location.href = `/dashboard`
        } else {
          window.location.href = `${response.data.url}`
        }
        setgetPay(true);
        // console.log('getpay', getPay);
      }
    })
    .catch(function (error) {
      // console.log(error);
      window.location.href = '/login'
    });
  }
  const unsubscribeRequest = () => {
    let accessToken = localStorage.getItem('accessToken')
    axios({
      method: 'patch',
      url: `${backLink}/clients/stripe/unsubscribe`,
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      },
    })
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  return (
    <React.Fragment>
      <LanguageProvider>
      <Router>
        <Switch>
        {/* <ProtectedRoute/> */}
          {/* {routes.map((route, idx) => (
            <Route path={route.path} component={route.component} key={idx} />
          ))} */}
          <Route
            path="/"
            exact
            render={() => (
            <Index1
              // navItems={navItems}
              priceId={priceId}
              setpriceId={setpriceId}
              stripeRequest={stripeRequest}
              setgetPay={setgetPay}
              />
              )}
          />
          {/* <Route
            path="/api"
            exact
            render={() => (localStorage.getItem('login') !== "true" ? <Index1 /> : <Dashboard />)}
          /> */}
          <ProtectedRoute
            login={login}
            first_name={first_name}
            last_name={last_name}
            setfirst_name={setfirst_name}
            setlast_name={setlast_name} 
            setdeconnexion={setdeconnexion}
            path='/dashboard'
            exact
            // navItems={navItems}
            component={Dashboard}
          />
          
            <Route
              path="/error"
              exact={true}
              component={ErrorNotFound}
            />
            <Route
              path="/password-forget"
              exact
              render={() => (localStorage.getItem('login') === "false" ?  <PasswordForget /> : <Redirect to="/dashboard" />)}
            />
            <Route
              path="/reset-password"
              exact
              render={() => (localStorage.getItem('login') === "false" ?  <ResetPassword /> : <Redirect to="/dashboard" />)}
            />
            <Route
              path="/login"
              exact
              render={() => (localStorage.getItem('login') === "false" ?  <Login /> : <Redirect to="/dashboard" />)}
            />
            <Route
              path="/sign-up"
              exact
              render={() => (localStorage.getItem('login') === "false" ?  <Signup /> : <Redirect to="/dashboard" />)}
              />
            <Route
              path="/confirm"
              exact
              render={() => (localStorage.getItem('login') === "false" ?  <VerificationCode /> : <Redirect to="/dashboard" />)}
            />
            <Route
              path="/documentation"
              exact
              render={() => (
                <Documentation
                  login={login}
                  setlogin={setlogin}
                  first_name={first_name}
                  last_name={last_name}
                  setfirst_name={setfirst_name}
                  setlast_name={setlast_name} 
                />
              )}
            />
            
            <Route
              path="/paysuccess"
              exact
              // render={() => (getPay === true && localStorage.getItem('login') === "true"? (<PaySuccess setgetPay={setgetPay} />) : (<Redirect to='/api' />))}
              render={() => (<PaySuccess setgetPay={setgetPay} />)}
            />
            <ProtectedRoute
              login={login}
              first_name={first_name}
              last_name={last_name}
              setfirst_name={setfirst_name}
              setlast_name={setlast_name} 
              path='/profil'
              exact
              unsubscribeRequest={unsubscribeRequest}
              component={Social}
            />
            {/* <Route exact path='/unauthorized' component={Unauthorized} /> */}
            <Redirect to="/error" />
        </Switch>
        {/* <Redirect to="/error" /> */}
      </Router>
    </LanguageProvider>
    </React.Fragment>
  );
}

export default App;
