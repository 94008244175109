/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect } from 'react';
import {
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { getCurrentUser } from '../../../helpers/Utils';
import dataPrice from "../../../constants/dataPrice";
import { Colxx } from '../../common/CustomBootstrap';
import { Text } from '../../../containers/Language';
const ModalUi = ({
  setmodalAlert,
  modalAlert,
  stripeRequest,
  setmodalBasic,
  modalBasic,
  unsubscribeRequest,
  pricing,
  newDeposit,
  created_date
}) => {
    const [language, setlanguage] = useState();
    const [subscription, setsubscription] = useState();
    const [currentDeposit, setcurrentDeposit] = useState();
    useEffect(() => {
      // console.log('pricing', newDeposit);
      dataPrice.data.forEach((data) => {
        if(getCurrentUser().allowed_monthly_deposits === data.deposit){
          setcurrentDeposit(getCurrentUser().allowed_monthly_deposits );
          setsubscription(parseInt(data.price.split('€')[0]));
        } else {
          return null;
        }
      })
      // console.log('current :', currentDeposit);
        setlanguage(localStorage.getItem('rcml-lang'));
    }, [language])
    const formatDateFR = () => {
      const d = new Date()
      let now = d.toISOString()
      // console.log('language', language);
      // console.log('geetcurrent', getCurrentUser().subscription_created_at);
      let day;
      let dayNow;
      let month;
      let year;
      day = getCurrentUser().subscription_created_at.split('-')[2].split('T')[0];
      dayNow = now.split('-')[2].split('T')[0];
      month = now.split('-')[1];
      year = now.split('-')[0];
      // console.log('now', now);
      // console.log('daydsdsdsd', day);
      // console.log('dayNow', dayNow);
      // console.log('month', month);
      // console.log('year', year);
      if(month === "12") {
        month = "01"
        year = parseInt(year) + 1
        return `${day}/${month}/${year}`
      } else {
        day = parseInt(day);
        dayNow = parseInt(dayNow);
        // console.log('daydsdsdsd', day);
        // console.log('dayNow', dayNow);
        if(dayNow > day) {
          // console.log('heynow1')
          month = parseInt(month) + 1;
          if(month.length === undefined) {
            return `${day}/0${month.toString()}/${year}`
          } else {
            return `${day}/${month.toString()}/${year}`
          }
        } else {
          // console.log('heynow2')
          return `${day}/${month}/${year}`
        }
      }
    }
    const formatDateEN = () => {
      const d = new Date()
      let now = d.toISOString()
      // console.log('language', language);
      let day;
      let dayNow;
      let month;
      let year;
      day = getCurrentUser().subscription_created_at.split('-')[2].split('T')[0];
      dayNow = now.split('-')[2].split('T')[0];
      month = now.split('-')[1];
      year = now.split('-')[0];
      // console.log('now', now);
      // console.log('daydsdsdsd', day);
      // console.log('month', month);
      // console.log('year', year);
      if(month === "12") {
        month = "01"
        return `${year}/${month}/${day}`
      } else {
        day = parseInt(day);
        dayNow = parseInt(dayNow);
        // console.log('daydsdsdsd', day);
        // console.log('dayNow', dayNow);
        if(dayNow > day) {
          month = parseInt(month) + 1;
          if(month.length === undefined) {
            return `${day}/0${month.toString()}/${year}`
          } else {
            return `${day}/${month.toString()}/${year}`
          }
        } else {
          return `${day}/${month}/${year}`
        }
      }
    }
    const priceThisMonthFR = () => {
      // console.log('modal getcurrent user', getCurrentUser())
      let due;
      if(parseInt(pricing.split('€')[0]) > subscription) {
        due = parseInt(pricing.split('€')[0]) - subscription
        return (
          <div>
          Souhaitez-vous vraiment modifier votre abonnement ? <br />
          - Vous serez automatiquement prélevé de {due}€ dès aujourd'hui <br />
          - Vous bénéficierez de {newDeposit - currentDeposit} dépôts supplémentaires jusqu'au {formatDateFR()} <br />
          - Après cette date, votre nouvel abonnement vous permettra de faire {newDeposit} dépôts par mois pour un montant de {pricing}.
          </div>
        )
      } else {
        return (
          <div>
            Souhaitez-vous vraiment modifier votre abonnement ? <br />
            - Vous paierez {pricing} à partir du {formatDateFR()}. <br />
            - Cliquer sur confirmer pour modifier votre abonnement.
          </div>
        )
      }
    }
    const priceThisMonthEN = () => {
      let due;
      if(parseInt(pricing.split('€')[0]) > subscription) {
        due = parseInt(pricing.split('€')[0]) - subscription
        return (
          <div>
          Are you sure you want to change your subscription ? <br />
          - You will be automatically debited from {due}€ from today <br />
          - You will benefit from {newDeposit - currentDeposit} additional deposits until {formatDateEN()} <br />
          - After this date, your new subscription will allow you to make {newDeposit} deposits per month for an amount of {pricing}.
          </div>
        )
      } else {
        return (
          <div>
            Are you sure you want to change your subscription ? <br />
            - You will pay {pricing} from the {formatDateEN()}. <br />
            - Click on confirm to modify the amount of the monthly debit.
          </div>
        )
      }
    }
  return (
    <>
      <Row>
        <Colxx xxs="12">
          <Card className="mb-4">
            <CardBody>
              <div>
                { modalAlert === true ? (
                  <Modal
                    style={{ background: "#fdf8f4"}}
                    isOpen={modalAlert}
                    toggle={() => setmodalAlert(!modalAlert)}
                  >
                    <ModalHeader style={{ background: "#fdf8f4"}}>
                      {/* hello */}
                    </ModalHeader>
                    {
                      language === 'en' ? (
                        <ModalBody style={{ background: "#fdf8f4"}}>
                          {priceThisMonthEN()}
                        </ModalBody>
                      ) : (
                        <ModalBody style={{ background: "#fdf8f4"}}>
                          {priceThisMonthFR()} 
                        </ModalBody>
                      )
                    }
                    <ModalFooter style={{ background: "#fdf8f4"}}>
                      <Button
                        style={{backgroundColor: "#fdf8f4", color: "#EB502E", border: "none"}}
                        onClick={() => setmodalAlert(false)}
                      >
                        <Text tid="general.cancel" />
                      </Button>{' '}
                      <Button
                        style={{backgroundColor: "#fdf8f4", color: "#EB502E", border: "none"}}
                        onClick={() => stripeRequest()}
                      >
                        <Text tid="general.confirm" />
                      </Button>{' '}
                    </ModalFooter>
                  </Modal>
                ) : null}
              </div>
            </CardBody>
          </Card>
          <Card className="mb-4">
            <CardBody>
              <div>
                { modalBasic === true ? (
                  <Modal
                    style={{ background: "#fdf8f4"}}
                    isOpen={modalBasic}
                    toggle={() => setmodalBasic(!modalBasic)}
                  >
                    <ModalHeader style={{ background: "#fdf8f4"}}>
                      {/* hello */}
                    </ModalHeader>
                    {
                      language === 'en' ? (
                        <ModalBody style={{ background: "#fdf8f4"}}>
                          Would you like to unsubscribe ? <br />
                          Your subscription will end on {formatDateEN()}.
                        </ModalBody>
                      ) : (
                        <ModalBody style={{ background: "#fdf8f4"}}>
                          Souhaitez-vous vous désinscrire de votre abonnement ?
                          Votre abonnement prendra fin le {formatDateFR()}.
                        </ModalBody>
                      )
                    }
                    <ModalFooter style={{ background: "#fdf8f4"}}>
                      <Button
                        style={{backgroundColor: "#fdf8f4", color: "#EB502E", border: "none"}}
                        onClick={() => setmodalBasic(false)}
                      >
                        <Text tid="general.cancel" />
                      </Button>{' '}
                      <Button
                        style={{backgroundColor: "#fdf8f4", color: "#EB502E", border: "none"}}
                        onClick={() => {
                            unsubscribeRequest();
                            setmodalBasic(false);
                          }
                        }
                      >
                        <Text tid="general.confirm" />
                      </Button>{' '}
                    </ModalFooter>
                  </Modal>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    </>
  );
};

export default ModalUi;
