import React, { useContext } from 'react';

import { languageOptions } from '../languages';
import { LanguageContext } from '../containers/Language';

export default function LanguageSelector(
  {
    setlanguage
  }
  ) {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = e => {
    userLanguageChange(e.target.value);
    setlanguage(e.target.value);
  }

  return (
    <select
      onChange={handleLanguageChange}
      value={userLanguage}
      style={{ border: "none" }}
    >
      {Object.entries(languageOptions).map(([id, name]) => (
        <option style={{ border: "none" }} key={id} value={id}>{name}</option>
      ))}
    </select>
  );
};
