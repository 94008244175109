import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import LanguageSelector from '../../components/LanguageSelector';

import { Text } from '../../containers/Language';

// import { useHistory } from 'react-router-dom';

import { backLink } from '../../constants/defaultValues';

import axios from 'axios';

const PasswordForget = () => {
  const [email, setemail] = useState('');
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const [language, setlanguage] = useState('');

  //let history = useHistory();
//  useEffect(() => {
//    console.log("window", window.location);
//  }, [])
  const forgetPassword = () => {
    // console.log("this.state.email", email);
    axios({
      method: 'post',
      url: `${backLink}/auth/reset-password`,
      headers: {
        'Content-Type': 'application/json'
      },
      data : {
        "email": email
      }
    })
    .then(function (response) {
      // console.log('response', JSON.stringify(response.status))
      if(JSON.stringify(response.status) === "200"){
        setsuccess(true);
        seterror(false);
        // history.push('/reset-password');
      }
      // console.log("lol", JSON.stringify(response.status));
    })
    .catch(function (error) {
      setsuccess(false);
      seterror(true);
      console.log(error);
    });
  }
 const handleEMAIL = (e) => {
    // console.log("e.target.value", e.target.value);
    setemail(e.target.value);
    // this.setState({
    //   email: e.target.value
    // })
  }
 const successNotification = () => {
  if(language === "en") {
    return (
      <div
        className="alert text-center"
        role="alert"
        style={{backgroundColor: '#23C552', color: "white" }}
      >
          a email have been sent on your email address
      </div>
    )
  } else {
    return (
      <div
        className="alert text-center"
        role="alert"
        style={{backgroundColor: '#23C552', color: "white" }}
      >
          un email a bien été envoyé à votre adresse email
      </div>
    )
  }
 }
 const errorNotification = () => {
  let locallang = localStorage.getItem('rcml-lang');
  if(language) {
    if(language === "en") {
    return(
      <div
        className="alert text-center"
        role="alert"
        style={{ backgroundColor: '#F84F31', color: "#FFFFFF" }}
      >
        your email address does not exist in our database, please retry with available email<br />Or create an account <a href="/sign-up">here</a>
      </div>
    )
    } else {
      return (
        <div
          className="alert text-center"
          role="alert"
          style={{ backgroundColor: '#F84F31', color: "#FFFFFF" }}
        >
          Votre adresse email n'existe pas dans notre base de donnée, merci de réessayer avec une adresse valide<br />Ou créer un compte <a href="/sign-up">ici</a>
        </div>
      )
    }
  } else {
    if(locallang === "en") {
      return(
        <div
          className="alert text-center"
          role="alert"
          style={{ backgroundColor: '#F84F31', color: "#FFFFFF" }}
        >
          your email address does not exist in our database, please retry with available email<br />Or create an account <a href="/sign-up">here</a>
        </div>
      )
      } else {
        return (
          <div
            className="alert text-center"
            role="alert"
            style={{ backgroundColor: '#F84F31', color: "#FFFFFF" }}
          >
            Votre adresse email n'existe pas dans notre base de donnée, merci de réessayer avec une adresse valide<br />Ou créer un compte <a href="/sign-up">ici</a>
          </div>
        )
      }
  }
}
 const placeholderEmail = () => {
   if(language === 'en') {
     return "enter your email address";
   } else {
     return "entrer votre adresse email";
   }
 }
    return (
      <React.Fragment>
        <div className="account-home-btn d-none d-sm-block">
          <Link to="/" className="text-white">
            <i className="mdi mdi-home h1"></i>
          </Link>
        </div>

        <section className="bg-account-pages vh-100">
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={5}>
                    <Card className="account-card">
                      <CardBody>
                      <LanguageSelector setlanguage={setlanguage} />
                        <div className="text-center mt-3">
                          <h3 className="font-weight-bold">
                            <Link
                              to="/"
                              style={{ color: "#eb502e"}}
                              className="text-uppercase account-pages-logo"
                            >
                              <img
                                src="../assets/images/logo/ipocamp.png"
                                style={{          
                                  height: 35,
                                  width: "110.63px",
                                  margin: 0,
                                }}
                                alt="logo"
                              />
                            </ Link>
                          </h3>
                          <p className="text-muted"><Text tid="forget.subtitle" /></p>
                        </div>
                        <div className="p-3">
                          <div
                            className="alert alert-warning  text-center"
                            role="alert"
                          >
                            <Text tid="forget.warning" />
                          </div>
                          <AvForm>
                            <FormGroup>
                              <Label htmlFor="email"><Text tid="forget.email" /></Label>
                              <AvField
                                onChange={handleEMAIL}
                                name="email"
                                required
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder={placeholderEmail()}
                              />
                            </FormGroup>
                            { success === true ? (successNotification()) : null}
                            { error === true ? (errorNotification()) : null}
                            <div className="d-grid mt-3">
                              <Button
                                onClick={forgetPassword}
                                color="none"
                                type="submit"
                                className="bg-color-chart text-chart"
                              >
                                <Text tid="forget.validationbutton" />
                              </Button>
                            </div>
                            
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }

export default PasswordForget;
